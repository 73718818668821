import "../src/Assets/scss/style.scss";

import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import Dashboard from "./demodashboard/Dashboard";
import Landing from "./Private/Landing";
import Footer from "./Public/HeaderFooter/Footer";
import Register from "./Public/Register";
import DasboardHeader from "./demodashboard/DasboardHeader";
import DashboardFooter from "./demodashboard/DashboardFooter";
import Partners from "./demodashboard/Partners";
import Stats from "./demodashboard/Stats";
import TelegramBot from "./demodashboard/TelegramBot";
import Links from "./demodashboard/Links";
import Information from "./demodashboard/Information";
import Header from "./Public/HeaderFooter/Header";
import Index from "./Public/Index";
import HomeHeader from "./HomePage/HomeHeader";
import HomeFooter from "./HomePage/HomeFooter";
import Web3 from 'web3';
import { InjectedConnector } from "@web3-react/injected-connector";
import detectEthereumProvider from '@metamask/detect-provider';
import { useEffect, useState } from "react";
import { supportedChainId, contractAddress, contractAbi, cyclixContractAddress, cyclixContractAbi, api_url } from "./config/config";
import _fetch from "./config/api";
import PrivateRoutes from "./config/PrivateRoutes";
import Profile from "./demodashboard/Profile";
import IncomeReport from "./demodashboard/IncomeReport";
import IncomeLedger from "./demodashboard/IncomeLedger";
import Teamtree from "./demodashboard/Teamtree";
import Sticky from "./demodashboard/Sticky";
import UserLevel from "./demodashboard/UserLevel";
import ViewUserLevel from "./demodashboard/ViewUserLevel";
import ViewDirect from "./demodashboard/ViewDirect";
import StakeList from "./demodashboard/StakeList";
import Directory from "./demodashboard/Directory";
import PoolTree from "./demodashboard/PoolTree";
import DirectTree from "./demodashboard/DirectTree";
import ComingSoon from "./Public/ComingSoon";
import PoolTreeX2 from "./demodashboard/PoolTreeX2";
import Tree from "./demodashboard/Tree";
import { ToastContainer } from "react-toastify";
import TreeX3 from "./demodashboard/TreeX3";
import TreeX3Pro from "./demodashboard/TreeX3Pro";


function App() {
  // const { deactivate } = useWeb3React();

  const ToggleButton = () => {
    const absx = document.body;
    absx.classList.toggle("ShowBar__INer");
  }



  function PublicLayout() {
    return (
      <>
        <HomeHeader />
        <Outlet />
        <HomeFooter />
      </>
    );
  }




  function PrivateLayout() {
    return (
      <>
        <div className="__bodyOuter">
          <DasboardHeader ToggleButton={ToggleButton} connectWallet={connectWallet} address={account} balance={{ coinBalance: coinBalance, tokenBalance: tokenBalance }} disconnectWallet={disconnectWallet} />
          <Outlet />
          <DashboardFooter />
        </div>
      </>
    );
  }



  ///////////////// web 3 start/////////
  const [web3, setWeb3] = useState(null);
  const [account, setAccount] = useState(null);
  const [coinBalance, setCoinBalance] = useState(0);
  const [tokenBalance, setTokenBalance] = useState(0);

  const [disconnectStataus, setdisconnectStataus] = useState(false);
  const [tokenContract, setTokenContract] = useState(null);
  const [cyclixContract, setCyclixContract] = useState(null);

  // const [approveLoader, setapproveLoader] = useState('0');
  // const [changeSection, setchangeSection] = useState(false);
  // const [activeLogin, setactiveLogin] = useState(false);
  const [getSopnser, setgetSopnser] = useState('');
  // const [gethash, setGethash] = useState('');
  // const [getSopnserWallet, setgetSopnserWallet] = useState('');


  useEffect(() => {
    // registerUser()
    if (disconnectStataus !== true) {
      const loadWeb3 = async () => {
        const provider = await detectEthereumProvider() || new InjectedConnector({ supportedChainIds: supportedChainId });

        if (provider) {
          if (provider.isMetaMask) {
            provider.autoRefreshOnNetworkChange = false;
          }

          const web3Instance = new Web3(provider);
          setWeb3(web3Instance);

          // 

          provider.on('accountsChanged', (newAccounts) => {
            if (newAccounts.length === 0) {
              // User disconnected wallet
              // setWeb3(null);
              setAccount(null);
            } else {
              setAccount(newAccounts[0]);
              getCoinBalances(web3Instance, newAccounts);
              getTokenBalances(web3Instance, accounts);


            }
          });

          // 

          const accounts = await web3Instance.eth.getAccounts();
          if (accounts.length > 0) {
            setAccount(accounts[0]);
            getCoinBalances(web3Instance, accounts);
            getTokenBalances(web3Instance, accounts);
            // console.log(formattedCoinBalance);

            const tokenContractInstance = new web3Instance.eth.Contract(cyclixContractAbi, cyclixContractAddress);
            // console.log(tokenContractInstance);
            setCyclixContract(tokenContractInstance);
          }


        }

      };

      loadWeb3();
    }

  }, [account, disconnectStataus]);


  const getCoinBalances = async (web3Instance, accounts) => {

    const chainIds = await web3.eth.getChainId();
    const chainId = parseInt(chainIds.toString().replace("n", ""));
    const getCoinBalance = await web3Instance.eth.getBalance(accounts[0]);
    const formattedCoinBalance = web3Instance.utils.fromWei(getCoinBalance, 'ether');
    if (formattedCoinBalance > 0) {
      setCoinBalance(formattedCoinBalance);
    }
  }

  const getTokenBalances = async (web3Instance, accounts) => {
    const chainIds = await web3.eth.getChainId();
    const chainId = parseInt(chainIds.toString().replace("n", ""));
    // console.log("ChainId", chainId);
    if (chainId != 56) {
      return switchNetwork(1);
    }
    const contract = new web3Instance.eth.Contract(contractAbi, contractAddress);
    setTokenContract(contract);

    // const currency = await contract.methods.symbol().call();
    const getTokenBalance = await contract.methods.balanceOf(accounts[0]).call();
    const tokenDecimals = await contract.methods.decimals().call();
    const tokenDecimalsMain = await parseInt(tokenDecimals.toString().replace("n", ""));
    const formattedBalance = getTokenBalance.toString().replace("n", "") / (10 ** tokenDecimalsMain);

    if (formattedBalance > 0) {
      setTokenBalance(formattedBalance);
    }



    const tokenContractInstance = new web3Instance.eth.Contract(cyclixContractAbi, cyclixContractAddress);
    // console.log(tokenContractInstance);
    setCyclixContract(tokenContractInstance);
  }

  const connectWallet = async (type) => {
    // console.log('type', type);
    // console.log('asdasd', web3);
    setdisconnectStataus(false)
    if (web3) {
      // console.log('sdfdsfsfsdsfd');
      try {
        web3.eth.getChainId().then(async chainIdd => {
          const chainId = chainIdd.toString().slice(0, -1);
          const hexChainId = await web3.utils.toHex(chainId);
          const checkChain = await supportedChainId.includes(hexChainId);
          // console.log(checkChain);
          // const newChain = chainId.toString().slice(0,-1);
          if (checkChain === false) {
            // console.log("test");
            switchNetwork(supportedChainId[0])
          }
        }

        );
        try {
          // console.log('chainId',chainId);
          await web3.currentProvider.enable(); // Prompt user to connect
          const accounts = await web3.eth.getAccounts();
          if (accounts.length > 0) {
            setAccount(accounts[0]);
            getCoinBalances(web3, accounts);
            getTokenBalances(web3, accounts);
            // console.log(formattedCoinBalance);
          }
        } catch (error) {
          // console.error('Error connecting wallet2:', error);
          if (type == 'metamsk') {
            // console.log(type, 'sdf');

            window.location.href = 'https://metamask.app.link/dapp/https://therichcrowd.com/login';
          }
          if (type != 'metamsk') {
            // console.log(type,'asdg');

            window.location.href = 'trustwallet://navigate?url=https://therichcrowd.com/login';
          }
        }
        // setAccount(accounts[0]);
      } catch (error) {
        console.error('Error connecting wallet:', error);
      }
    } else {
      // console.log(type);
      if (type == 'metamsk') {
        window.location.href = 'https://metamask.app.link/dapp/https://therichcrowd.com/login';
      }
      if (type == 'trust') {
        // console.log(type);

        window.location.href = 'trustwallet://navigate?url=https://therichcrowd.com/login';
      }
    }

  };



  const switchNetwork = async (receiveChainId) => {
    if (web3) {
      try {
        // console.log(receiveChainId);
        await web3.currentProvider.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: "0x38", // BSC Testnet chain ID
              chainName: 'SMART CHAIN',
              nativeCurrency: {
                name: 'BNB',
                symbol: 'BNB',
                decimals: 18,

              },
              rpcUrls: ['https://bsc-dataseed.binance.org/'], // Testnet RPC URL
              blockExplorerUrls: ['https://bscscan.com'], // Testnet explorer URL
            },
          ],
        });

        // The user might need to reconnect their wallet after switching networks
      } catch (error) {
        console.error('Error switching network:', error);
      }
    }
  };



  const disconnectWallet = async () => {
    // console.log('dis', web3);
    localStorage.clear();

    // if (web3) {
    //   web3.providers.HttpProvider.prototype.disconnect();
    setWeb3(null);
    setAccount(null);
    // }
    setdisconnectStataus(true)
    var data = await _fetch(`${api_url}auth/logout`, 'GET', {});

    if (data?.status === 'success') {
      window.location.reload();

      // toasted.success(data?.message);
      localStorage.clear();
    } else {
      // toasted.error(data?.message);
    }

    //     const ethereum = window.ethereum
    //       if (ethereum) {
    //       // Listening to Event
    //       ethereum.on('disconnect', () => {
    //         console.log("MetaMask discconnected")
    //         // this.setState({ metamaskHasDisonnected: true })
    //   })
    // }
  };


  /// final register

  // const approveAndSendTokens = async () => {


  //   if (web3 && account && tokenContract) {
  //     setapproveLoader('1')

  //     const approvalAmount = web3.utils.toWei('25', 'ether'); // Replace with the desired approval amount
  //     try {
  //       const approvalClear = await tokenContract.methods.approve(cyclixContractAddress, approvalAmount).send({
  //         from: account
  //       }).once('transactionHash', function (hash) {
  //         console.log('approval hash: ', hash);

  //       }).on('error', function (error) {
  //         console.log('\n[ERROR]\n\n' + error
  //           .message);
  //         let msg = error.message.split(":");
  //         // toastr.error("Error " + error.code +
  //         //     " : " + msg[1]);
  //       }).then(async function (receipt) {
  //         setapproveLoader('2')
  //         setchangeSection(false)
  //         const getEstimatedGas = await cyclixContract.methods.register(getSopnserWallet).estimateGas({ from: account });
  //         const estimatedGas = await parseInt(getEstimatedGas.toString().replace("n", ""));
  //         await cyclixContract.methods.register(getSopnserWallet).send({
  //           from: account,
  //           gas: estimatedGas
  //         }).once('transactionHash', function (hash) {
  //           console.log('transactionHash hash: ', hash);

  //           console.log(hash);
  //           setGethash(hash)
  //           setchangeSection(true)
  //         }).on('error', function (error) {
  //           console.log('\n[ERROR]\n\n' + error
  //             .message);
  //           let msg = error.message.split(":");
  //           // toastr.error("Error " + error.code +
  //           //     " : " + msg[1]);
  //         }).then(async function (receipt) {
  //           // call node apiLOG
  //           console.log(receipt.transactionHash);
  //           registerUser(receipt.transactionHash)
  //           setactiveLogin(true)


  //         })
  //       })

  //       console.log('Tokens approved and sent successfully');
  //     } catch (error) {
  //       console.error('Error approving and sending tokens:', error);
  //     }
  //   }
  // };

  // const registerUser = async (getHeshID) => {
  //   const formData = {
  //     "ref_id": getSopnser,
  //     "wallet_address": account,
  //     "amount": package_amount?.[0],
  //     "hash": getHeshID,
  //   }
  //   const data = await _fetch(`${api_url}auth/signup`, 'post', formData, {});
  //   if (data.status === 'success') {
  //     // setregisterStatus(data.data.status);
  //   } else {
  //     // setregisterStatus(true);
  //   }
  // }



  function PreviewLayout() {
    return (
      <>
        <Header connectWallet={connectWallet} address={account} balance={{ coinBalance: coinBalance, tokenBalance: tokenBalance }} disconnectWallet={disconnectWallet} />
        <Outlet />
        <Footer />
      </>
    );
  }

  function LoginLayout() {
    return (
      <>
        <div className="Login___sec">
          <Header connectWallet={connectWallet} address={account} balance={{ coinBalance: coinBalance, tokenBalance: tokenBalance }} disconnectWallet={disconnectWallet} />
          <Outlet />
        </div>
      </>
    );
  }

  function StickyLayout() {
    return (
      <>
        <DasboardHeader ToggleButton={ToggleButton} connectWallet={connectWallet} address={account} balance={{ coinBalance: coinBalance, tokenBalance: tokenBalance }} disconnectWallet={disconnectWallet} />
        <Outlet />
      </>
    );
  }

  function Soon() {
    return (
      <>
        <div className="Login___sec">
          <Outlet />
        </div>
      </>
    );
  }




  const passprops = {
    coinBalance: parseFloat(coinBalance).toFixed(3),
    tokenBalance: parseFloat(tokenBalance).toFixed(3),
    address: account,
    // approveLoader: approveLoader,
    // changeSection: changeSection,
    // activeLogin: activeLogin,
    // setgetSopnser: setgetSopnser,
    // getSopnser: getSopnser,
    web3: web3,
    tokenContract: tokenContract,
    cyclixContract: cyclixContract,


    // setgetSopnserWallet: setgetSopnserWallet,
  }

  // console.log('getSopnser', getSopnser, getSopnserWallet);
  return (
    <>
      <BrowserRouter>
        <Routes>

          <Route element={<LoginLayout />}>
            <Route path="/registration" element={<Register maskData={passprops} />} />
            <Route path="/registration/:url_sponser" element={<Register maskData={passprops} />} />
          </Route>

          <Route element={<PublicLayout />}>
            <Route exact path="/" element={<Index />} />
            <Route exact path="*" element={<Index />} />

          </Route>

          {/* <Route element={<PublicLayout />}>
            <Route exact path="/" element={<Index />} />
          </Route> */}

          <Route element={<PreviewLayout />}>
            <Route path="/login" element={<Landing address={account} />} />
          </Route>

          <Route element={<PrivateRoutes />}>

            <Route element={<PrivateLayout />}>

              <Route path="/dashboard" element={<Dashboard maskData={passprops}  connectWallet={connectWallet} />} />
              <Route path="/partners" element={<Partners />} />
              <Route path="/view-directs/:pass_add" element={<ViewDirect />} />

              <Route path="/level" element={<UserLevel />} />
              <Route path="/view-level/:level" element={<ViewUserLevel />} />

              <Route path="/income/:type" element={<IncomeReport />} />
              <Route path="/income-ledger" element={<IncomeLedger />} />
              <Route path="/stake-list" element={<StakeList />} />

              <Route path="/stats" element={<Stats />} />
              <Route path="/personal-link" element={<Links />} />
              <Route path="/telegrambot" element={<TelegramBot />} />
              <Route path="/instruction" element={<Information />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/team-tree/:address" element={<Teamtree />} />
            </Route>

            <Route element={<StickyLayout />}>
              <Route path="/directory" element={<Directory />} />
              <Route path="/tree-x2/:username" element={<Tree />} />
              <Route path="/tree-x3/:username" element={<TreeX3 />} />
              <Route path="/tree-x3-pro/:username" element={<TreeX3Pro />} />

              <Route path="/x3/:address" element={<PoolTree />} />
              <Route path="/x2/:address" element={<PoolTreeX2 />} />
              <Route path="/direct-tree/:address" element={<DirectTree />} />

              <Route path="/upgrade-account/:package_amount" element={<Sticky maskData={passprops} />} />

            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </>
  );
}

export default App;
