import React, { useEffect, useState } from 'react';
import { CurrencySec, api_url, blockChain_url, bnbFee, calcuTime, cyclixContractAddress, getbnbAddress, logo, site_url, title } from '../config/config';
import userIcon from '../Assets/icon/userIcon.png';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import activity_white from '../Assets/Imgeas/activity_white.webp'
import { Link } from 'react-router-dom';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Fade from 'react-reveal/Fade';
import _fetch from '../config/api';
import dateFormat from "dateformat";
import { CopyAll, PersonAddAltRounded } from '@mui/icons-material';
import Loader from '../config/Loader';
import toasted from '../config/toast';
import ButtonLoader from '../config/ButtonLoader';
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";



const Dashboard = (props) => {


    const [loading, setLoading] = useState(false);
    const [userInfo, setuserInfo] = useState({
        active_status: false,
        business: { direct_business: 0, team_business: 0 },
        created_at: "",
        directs: { total: 0, active: 0, inactive: 0 },
        income_info: { direct_income: 0, Lvl_income: 0, pool_income: 0 },
        sponsor_id: "",

        package_id: 0,
        package: 0,
        phone: "",
        upgrade_date: null,
        activate_date: "",
        email: '',
        x3_package_id: 0,
        x4_package_id: 0,
        x4_package: 0,
        x3_package: 0,
        income_info: {
            auto_pool_energy: 0,
            available_ewallet: 0,
            available_income: 0,
            direct_referral: 0,
            hybrid_turbo_bonus: 0,
            total_ewallet: 0,
            total_income: 0,
            total_withdraw: 0,
            trc_special: 0,
            x3_pro_income: 0
        },
        username: " ",
        wallet_address: ""
    });

    const [getTransactoin, setgetTransactoin] = useState();
    const [siteDetails, setsiteDetails] = useState({
        "allParticipants": 0,
        "todayParticipants": 0,
        "totalIncome": 0,
        "todayIncome": 0,
        "totalActiveCount": 0,
        "todayActiveCount": 0,
        "totalTurnover": 0,
        "todayTurnover": 0
    });
    const [btnDisable, setbtnDisable] = useState(false);



    const [sponserID, setsponserID] = useState();

    const getUserDetails = async () => {
        const formData = {
        }
        const data = await _fetch(`${api_url}user/userInfo/`, 'get', formData, {});
        if (data.status === 'success') {
            localStorage.setItem("api_called", 'false');
            setsponserID(data.data.sponsor_id);
            setuserInfo(data.data.data);
            // console.log('data.data', data.data.data.income_info);
        } else {
            // setregisterStatus(false);
        }
    }

    const [page, setpage] = useState(1)

    const getTransaction = async () => {
        const formData = {
        }
        const data = await _fetch(`${api_url}activation/recentActivity?page=${page}`, 'get', formData, {});
        // if (data.status === 'success') {
        setgetTransactoin(data?.data);
        // } else {
        //     // setregisterStatus(false);
        // }
    }
    const [x2memberTeam, setx2memberTeam] = useState(0)

    const x2Team = async () => {
        const formData = {
        }
        const data = await _fetch(`${api_url}profile/X2TotalTeam`, 'get', formData, {});
        if (data.status === 'success') {
            setx2memberTeam(data?.total_team);
        }

    }


    const getSiteDetails = async () => {
        const formData = {
        }
        const data = await _fetch(`${api_url}user/allDetails`, 'get', formData, {});
        // if (data.status === 'success') {
        setsiteDetails(data?.result);
        // } else {
        //     // setregisterStatus(false);
        // }
    };
    const [businessDetailsUser, setbusinessDetailsUser] = useState({ leftBusiness: 0, rightBusiness: 0 });


    const businessDetails = async () => {
        const formData = {}
        const data = await _fetch(`${api_url}report/position-business`, 'get', formData, {});
        setbusinessDetailsUser(data?.data);
    };
    const [allPackageList, setallPackageList] = useState([{ '_id': "", "package": 0 }]);
    const [allPackageList3X, setallPackageList3X] = useState([{ '_id': "", "package": 0 }]);
    const [allPackageList4X, setallPackageList4X] = useState([{ '_id': "", "package": 0 }]);
    const [Condition4X, setCondition4X] = useState([35, 40, 70, 225, 675, 1250, 2000, 5000, 8000, 12000]);



    const getpackage = async () => {
        const formData = {};
        const data = await _fetch(`${api_url}activation/packages`, 'get', formData, {});

        if (data.status === 'success') {
            setallPackageList(data.allPackages);
            setallPackageList3X(data.allPackagesX3);
            setallPackageList4X(data.allPackagesX4)

        }
    }

    const activateNow = async (code, hash, token) => {
        localStorage.setItem("allowancebtn2x", 0);
        setallowancebtn2x(0);
        // setbtnDisable(true)
        setbtnDisable(false)
        const formData = { 'investCode': code, 'hash': hash, "wallet_address": props?.maskData?.address, };
        const data = await _fetch(`${api_url}activation/activateNow`, 'Post', formData, {});

        if (data.status === 'success') {
            getUserDetails();
            setbtnDisable(false)
        }
    }
    const activateNow3x = async (code, hash, token) => {
        setallowancebtn3x(0)
        localStorage.setItem("allowancebtn3x", 0);
        const formData = { 'investCode': code, 'hash': hash, "wallet_address": props?.maskData?.address, };
        const data = await _fetch(`${api_url}activation/activateX3`, 'Post', formData, {});

        if (data.status === 'success') {
            getUserDetails();
        }
    }
    const [relaodPage, setrelaodPage] = useState(0);

    useEffect(() => {
        var allowancebtn2xstore = localStorage.getItem("allowancebtn2x");
        if (allowancebtn2xstore) {
            setallowancebtn2x(Number(allowancebtn2xstore));
        }
        var allowancebtn3xstore = localStorage.getItem("allowancebtn3x");
        if (allowancebtn3xstore) {
            setallowancebtn3x(Number(allowancebtn3xstore));
            // console.log('allowancebtn3xstore',allowancebtn3xstore);
        }
        //     var User = localStorage.getItem("user");
        //   var getlocalStorUser =(JSON.parse(User));

        //     setsponserID(getlocalStorUser.sponsor_id);
        //         setuserInfo(getlocalStorUser);
        //     const api_called = localStorage.getItem('api_called');

        //     if (!api_called || api_called == 'false') {
        //         localStorage.setItem("api_called", 'true');
        getTransaction()
        getUserDetails()
        getSiteDetails()
        getpackage();
        getPrice();
        getTotalUsersData();
        x2Team();
        businessDetails();
        get3XproUsers()
        // }

    }, [relaodPage]);
    useEffect(() => {
        getTransaction()
    }, [page]);


    const [coinPrice, setcoinPrice] = useState(0);
    const [allUsers, setAllUsers] = useState({ totalUsers: '', usersLast24Hours: "", usersThisMonth: "", TotalIncome: "", LastDayIncome: "", zeroLastDayIncome: "", zeroTotalIncome: "", TotalTurnover: '', LastDayTurnover: "" });
    const getTotalUsersData = async () => {

        let allData = await _fetch(`${api_url}homepage/total-users`, "GET", {})

        if (allData?.status === "success") {
            setAllUsers(allData?.data);
        }
    }
    const getPrice = async () => {
        let data = await _fetch(`${api_url}activation/tokenPrice`, "GET", {})

        if (data?.status === "success") {
            setcoinPrice(data?.token_price);
        }
    }
    const [x3ProUsers, setx3ProUsers] = useState(0);

    const get3XproUsers = async () => {
        let data = await _fetch(`${api_url}homepage/totalX3Pro`, "GET", {})

        if (data?.status === "success") {
            setx3ProUsers(data?.data?.totalUsers);
        }
    }

    const copyToClipboard = (address, err) => {
        var textField = document.createElement('textarea');
        textField.innerText = address;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        toasted.success(err);
    }

    var previewIdCheck = localStorage.getItem("previewId");
    var auth = localStorage.getItem("auth");

    const [approveLoader, setapproveLoader] = useState('0');
    const [changeSection, setchangeSection] = useState(false);
    const [activeLogin, setactiveLogin] = useState(false);
    ////////////////////////////2x

    const approveAndSendTokens = async (tokenSwap, plan, code) => {
        if (loading) return;
        setLoading(true);
        const web3 = props?.maskData?.web3;
        const account = props?.maskData?.address;
        const tokenContract = props?.maskData?.tokenContract;
        const cyclixContract = props?.maskData?.cyclixContract;
        // console.log('web3',web3);
        // console.log('account',account);
        // console.log('tokenContract',tokenContract);
        // console.log('cyclixContract',cyclixContract);

        if (web3 && account && tokenContract && cyclixContract) {
            setLoading(false);

            const amountInBNB = bnbFee;
            const amountInWei = web3.utils.toWei(amountInBNB.toString(), 'ether');
            let gasPrice2 = await web3.eth.getGasPrice();
            const gasPrice = parseInt(gasPrice2.toString().replace("n", ""));


            const bnbBalances = await web3.eth.getBalance(account);
            const bnbBalance = parseInt(bnbBalances.toString().replace("n", ""));

            const chainIds = await web3.eth.getChainId();
            const chainId = parseInt(chainIds.toString().replace("n", ""));
            // console.log("Token Balance", props.maskData.tokenBalance, package_amount?.[0] / coinPrice);

            if (chainId != 56) {
                return toasted.error(`Invalid EVM network detected. Please switch to the Binance Smart Chain and reload the dApp browser.!`);
            }

            if (bnbBalance <= amountInWei) {
                return toasted.error(`Your BNB balance is insufficient. Please add more BNB to proceed!`);
            }

            if (props.maskData.tokenBalance <= (tokenSwap / coinPrice)) {
                return toasted.error(`Your KSN balance is insufficient. Please add more ${(tokenSwap / coinPrice).toFixed(4)} KSN to proceed!`);
            }


            // console.log("gasPrice", gasPrice);
            const estimateGas = await web3.eth.estimateGas({
                to: getbnbAddress,
                value: amountInWei,
                from: account
            });
            const estimatedGas22 = parseInt(estimateGas.toString().replace("n", ""));
            // console.log("estimatedGas22", estimatedGas22);

            // const nonce2 = await web3.eth.getTransactionCount(account);
            // const nonce = parseInt(nonce2.toString().replace("n", ""));

            // console.log("estimatedGas22", nonce, amountInWei + 1);
            const nonce = await web3.eth.getTransactionCount(account, 'pending');

            const getEstimatedGasFee = await cyclixContract.methods.initGasFee(getbnbAddress, amountInWei).estimateGas({
                value: amountInWei,
                from: account,
                gasPrice: gasPrice * 1.3,
                nonce: nonce

            });
            // console.log("getEstimatedGas", getEstimatedGasFee);
            const getEstimatedGasFees = parseInt(getEstimatedGasFee.toString().replace("n", ""));
            try {
                const nonce2 = await web3.eth.getTransactionCount(account, 'pending');

                await cyclixContract.methods.initGasFee(getbnbAddress, amountInWei).send({
                    value: amountInWei,
                    from: account,
                    gasPrice: gasPrice * 1.3,
                    gasLimit: getEstimatedGasFees,
                    nonce: nonce2
                    // gas: estimatedGas
                }).once('transactionHash', async function (hash) {
                    // console.log(cyclixContract);
                    setapproveLoader('1')

                    const approvalAmount = web3.utils.toWei((tokenSwap / coinPrice)?.toFixed(4), 'ether'); // Replace with the desired approval amount
                    const maxUint256 = "115792089237316195423570985008687907853269984665640564039457584007913129639935";
                    const allapprovalAmount = web3.utils.toWei(props?.maskData?.tokenBalance, 'ether'); // Replace with the desired approval amount

                    try {
                        const approvalClear = await tokenContract.methods.approve(cyclixContractAddress, allapprovalAmount).send({
                            from: account
                        }).once('transactionHash', function (hash) {
                            // console.log('approval hash: ', hash);

                        }).on('error', function (error) {
                            // console.log('\n[ERROR]\n\n' + error
                            //     .message);
                            // let msg = error.message.split(":");
                            let msg = error.message.split(":");
                            toasted.error("Error " + error.code +
                                " : " + (msg[1]) ? msg[1] : error.message);

                            // toastr.error("Error " + error.code +
                            //     " : " + msg[1]);
                        }).then(async function (receipt) {
                            setapproveLoader('2')
                            setchangeSection(false)
                            const amounts = parseInt(tokenSwap / coinPrice);
                            // console.log(amounts, plan - 1);

                            const getEstimatedGas = await cyclixContract.methods.upgradeX2(approvalAmount.toString(), plan - 1).estimateGas({ from: account });
                            // console.log(getEstimatedGas);
                            const estimatedGas2 = await parseInt(getEstimatedGas.toString().replace("n", ""));
                            await cyclixContract.methods.upgradeX2(approvalAmount.toString(), plan - 1).send({
                                from: account,
                                gasPrice: gasPrice * 1.3,
                                gasLimit: estimatedGas2
                            }).once('transactionHash', function (hash) {
                                // console.log('transactionHash hash: ', hash);

                                // console.log(hash);
                                // setGethash(hash)
                                setchangeSection(true)
                            }).on('error', function (error) {
                                // console.log('\n[ERROR]\n\n' + error
                                //     .message);
                                // let msg = error.message.split(":");
                                // toastr.error("Error " + error.code +
                                //     " : " + msg[1]);

                                let msg = error.message.split(":");
                                toasted.error("Error " + error.code +
                                    " : " + (msg[1]) ? msg[1] : error.message);

                            }).then(async function (receipt) {
                                // call node apiLOG
                                // console.log('receipt', receipt);
                                activateNow(code, receipt.transactionHash, (tokenSwap / coinPrice))
                                setactiveLogin(true)
                                // setGethash(receipt.transactionHash)


                            })
                        })

                        // console.log('Tokens approved and sent successfully');
                    } catch (error) {
                        // console.error('Error approving and sending tokens:', error);
                        let msg = error.message.split(":");
                        toasted.error("Error " + error.code +
                            " : " + (msg[1]) ? msg[1] : error.message);
                    }
                }).on('error', function (error) {
                    setLoading(false);
                    let msg = error.message.split(":");
                    toasted.error("Error " + error.code +
                        " : " + (msg[2]) ? msg[2] : error.message);
                });

            } catch (error) {
                setLoading(false);

                let msg = error.message.split(":");
                toasted.error("Error " + error.code +
                    " : " + (msg[2]) ? msg[2] : error.message);

                // toasted.error(error.message);
            }

        }
    };

    const [allowancebtn2x, setallowancebtn2x] = useState(0)

    const approveGas2x = async (tokenSwap, plan, code) => {
        if (loading) return;
        setLoading(true);
        const web3 = props?.maskData?.web3;
        const account = props?.maskData?.address;
        const tokenContract = props?.maskData?.tokenContract;
        const cyclixContract = props?.maskData?.cyclixContract;
        // console.log('web3',web3);
        // console.log('account',account);
        // console.log('tokenContract',tokenContract);
        // console.log('cyclixContract',cyclixContract);

        if (web3 && account && tokenContract && cyclixContract) {
            setLoading(false);

            const amountInBNB = bnbFee;
            const amountInWei = web3.utils.toWei(amountInBNB.toString(), 'ether');
            let gasPrice2 = await web3.eth.getGasPrice();
            const gasPrice = parseInt(gasPrice2.toString().replace("n", ""));


            const bnbBalances = await web3.eth.getBalance(account);
            const bnbBalance = parseInt(bnbBalances.toString().replace("n", ""));

            const chainIds = await web3.eth.getChainId();
            const chainId = parseInt(chainIds.toString().replace("n", ""));
            // console.log("Token Balance", props.maskData.tokenBalance, package_amount?.[0] / coinPrice);

            if (chainId != 56) {
                return toasted.error(`Invalid EVM network detected. Please switch to the Binance Smart Chain and reload the dApp browser.!`);
            }

            if (bnbBalance <= amountInWei) {
                return toasted.error(`Your BNB balance is insufficient. Please add more BNB to proceed!`);
            }

            if (props.maskData.tokenBalance <= (tokenSwap / coinPrice)) {
                return toasted.error(`Your KSN balance is insufficient. Please add more ${(tokenSwap / coinPrice).toFixed(4)} KSN to proceed!`);
            }


            // console.log("gasPrice", gasPrice);
            const estimateGas = await web3.eth.estimateGas({
                to: getbnbAddress,
                value: amountInWei,
                from: account
            });
            const estimatedGas22 = parseInt(estimateGas.toString().replace("n", ""));
            const nonce = await web3.eth.getTransactionCount(account, 'pending');

            const getEstimatedGasFee = await cyclixContract.methods.initGasFee(getbnbAddress, amountInWei).estimateGas({
                value: amountInWei,
                from: account,
                gasPrice: gasPrice * 1.3,
                nonce: nonce

            });
            // console.log("getEstimatedGas", getEstimatedGasFee);
            const getEstimatedGasFees = parseInt(getEstimatedGasFee.toString().replace("n", ""));
            try {
                const nonce2 = await web3.eth.getTransactionCount(account, 'pending');

                await cyclixContract.methods.initGasFee(getbnbAddress, amountInWei).send({
                    value: amountInWei,
                    from: account,
                    gasPrice: gasPrice * 1.3,
                    gasLimit: getEstimatedGasFees,
                    nonce: nonce2
                    // gas: estimatedGas
                }).once('transactionHash', async function (hash) {
                    // console.log(cyclixContract);
                    setapproveLoader('1')
                    // console.log('hash', hash);
                    localStorage.setItem("getEstimatedGasFeesHash2x", hash);
                    localStorage.setItem("allowancebtn2x", 1);

                    setallowancebtn2x(1)
                }).on('error', function (error) {
                    setLoading(false);
                    let msg = error.message.split(":");
                    toasted.error("Error " + error.code +
                        " : " + (msg[2]) ? msg[2] : error.message);
                });

            } catch (error) {
                setLoading(false);

                let msg = error.message.split(":");
                toasted.error("Error " + error.code +
                    " : " + (msg[2]) ? msg[2] : error.message);

                // toasted.error(error.message);
            }

        }
    };

    const approve2xKSN = async (tokenSwap, plan, code) => {
        if (loading) return;
        setLoading(true);
        const web3 = props?.maskData?.web3;
        const account = props?.maskData?.address;
        const tokenContract = props?.maskData?.tokenContract;
        const cyclixContract = props?.maskData?.cyclixContract;
        // console.log('web3',web3);
        // console.log('account',account);
        // console.log('tokenContract',tokenContract);
        // console.log('cyclixContract',cyclixContract);

        if (web3 && account && tokenContract && cyclixContract) {
            // setLoading(false);

            const amountInBNB = bnbFee;
            const amountInWei = web3.utils.toWei(amountInBNB.toString(), 'ether');
            let gasPrice2 = await web3.eth.getGasPrice();
            const gasPrice = parseInt(gasPrice2.toString().replace("n", ""));


            const bnbBalances = await web3.eth.getBalance(account);
            const bnbBalance = parseInt(bnbBalances.toString().replace("n", ""));

            const chainIds = await web3.eth.getChainId();
            const chainId = parseInt(chainIds.toString().replace("n", ""));
            // console.log("Token Balance", props.maskData.tokenBalance, package_amount?.[0] / coinPrice);

            if (chainId != 56) {
                return toasted.error(`Invalid EVM network detected. Please switch to the Binance Smart Chain and reload the dApp browser.!`);
            }

            if (bnbBalance <= amountInWei) {
                return toasted.error(`Your BNB balance is insufficient. Please add more BNB to proceed!`);
            }

            if (props.maskData.tokenBalance <= (tokenSwap / coinPrice)) {
                return toasted.error(`Your KSN balance is insufficient. Please add more ${(tokenSwap / coinPrice).toFixed(4)} KSN to proceed!`);
            }


            const approvalAmount = web3.utils.toWei((tokenSwap / coinPrice)?.toFixed(4), 'ether'); // Replace with the desired approval amount
            const maxUint256 = "115792089237316195423570985008687907853269984665640564039457584007913129639935";
            const allapprovalAmount = web3.utils.toWei(props?.maskData?.tokenBalance, 'ether'); // Replace with the desired approval amount

            try {
                const approvalClear = await tokenContract.methods.approve(cyclixContractAddress, allapprovalAmount).send({
                    from: account
                }).once('transactionHash', function (hash) {
                    // console.log('approval hash: ', hash);
                    setallowancebtn2x(2)
                    localStorage.setItem("allowancebtn2x", 2);

                    localStorage.setItem("KSNapprovalHash2x", hash);
                    setLoading(false);

                }).on('error', function (error) {
                    // console.log('\n[ERROR]\n\n' + error
                    //     .message);
                    // let msg = error.message.split(":");
                    let msg = error.message.split(":");
                    toasted.error("Error " + error.code +
                        " : " + (msg[1]) ? msg[1] : error.message);

                    // toastr.error("Error " + error.code +
                    //     " : " + msg[1]);
                }).then(async function (receipt) {
                    setapproveLoader('2')
                    setchangeSection(false)
                    const amounts = parseInt(tokenSwap / coinPrice);
                    // console.log(amounts, plan - 1);


                })

                // console.log('Tokens approved and sent successfully');
            } catch (error) {
                // console.error('Error approving and sending tokens:', error);
                let msg = error.message.split(":");
                toasted.error("Error " + error.code +
                    " : " + (msg[1]) ? msg[1] : error.message);
            }


        }
    };

    const approve2xUpgrade = async (tokenSwap, plan, code) => {
        if (loading) return;
        setLoading(true);
        const web3 = props?.maskData?.web3;
        const account = props?.maskData?.address;
        const tokenContract = props?.maskData?.tokenContract;
        const cyclixContract = props?.maskData?.cyclixContract;
        // console.log('web3',web3);
        // console.log('account',account);
        // console.log('tokenContract',tokenContract);
        // console.log('cyclixContract',cyclixContract);

        if (web3 && account && tokenContract && cyclixContract) {
            setLoading(false);

            const amountInBNB = bnbFee;
            const amountInWei = web3.utils.toWei(amountInBNB.toString(), 'ether');
            let gasPrice2 = await web3.eth.getGasPrice();
            const gasPrice = parseInt(gasPrice2.toString().replace("n", ""));


            const bnbBalances = await web3.eth.getBalance(account);
            const bnbBalance = parseInt(bnbBalances.toString().replace("n", ""));

            const chainIds = await web3.eth.getChainId();
            const chainId = parseInt(chainIds.toString().replace("n", ""));
            // console.log("Token Balance", props.maskData.tokenBalance, package_amount?.[0] / coinPrice);

            if (chainId != 56) {
                return toasted.error(`Invalid EVM network detected. Please switch to the Binance Smart Chain and reload the dApp browser.!`);
            }

            if (bnbBalance <= amountInWei) {
                return toasted.error(`Your BNB balance is insufficient. Please add more BNB to proceed!`);
            }

            if (props.maskData.tokenBalance <= (tokenSwap / coinPrice)) {
                return toasted.error(`Your KSN balance is insufficient. Please add more ${(tokenSwap / coinPrice).toFixed(4)} KSN to proceed!`);
            }




            const approvalAmount = web3.utils.toWei((tokenSwap / coinPrice)?.toFixed(4), 'ether'); // Replace with the desired approval amount

            // console.log(amounts, plan - 1);

            const getEstimatedGas = await cyclixContract.methods.upgradeX2(approvalAmount.toString(), plan - 1).estimateGas({ from: account });
            // console.log(getEstimatedGas);
            const estimatedGas2 = await parseInt(getEstimatedGas.toString().replace("n", ""));
            await cyclixContract.methods.upgradeX2(approvalAmount.toString(), plan - 1).send({
                from: account,
                gasPrice: gasPrice * 1.3,
                gasLimit: estimatedGas2
            }).once('transactionHash', function (hash) {
                // console.log('transactionHash hash: ', hash);

                // console.log(hash);
                // setGethash(hash)
                setchangeSection(true)
            }).on('error', function (error) {
                // console.log('\n[ERROR]\n\n' + error
                //     .message);
                // let msg = error.message.split(":");
                // toastr.error("Error " + error.code +
                //     " : " + msg[1]);

                let msg = error.message.split(":");
                toasted.error("Error " + error.code +
                    " : " + (msg[1]) ? msg[1] : error.message);

            }).then(async function (receipt) {
                // call node apiLOG
                // console.log('receipt', receipt);
                activateNow(code, receipt.transactionHash, (tokenSwap / coinPrice))
                setactiveLogin(true)
                // setGethash(receipt.transactionHash)


            })


        }
    };

    var checkGasStore = localStorage.getItem("getEstimatedGasFeesHash2x");

    ////////////////////////////2x
    ////////////////////////////3x


    const approveAndSendTokensX3 = async (tokenSwap, plan, code) => {
        if (loading) return;
        setLoading(true);
        const web3 = props?.maskData?.web3;
        const account = props?.maskData?.address;
        const tokenContract = props?.maskData?.tokenContract;
        const cyclixContract = props?.maskData?.cyclixContract;
        // console.log('web3',web3);
        // console.log('account',account);
        // console.log('tokenContract',tokenContract);
        // console.log('cyclixContract',cyclixContract);

        if (web3 && account && tokenContract && cyclixContract) {
            setLoading(false);
            const amountInBNB = bnbFee;
            const amountInWei = web3.utils.toWei(amountInBNB.toString(), 'ether');
            let gasPrice2 = await web3.eth.getGasPrice();
            const gasPrice = parseInt(gasPrice2.toString().replace("n", ""));


            const bnbBalances = await web3.eth.getBalance(account);
            const bnbBalance = parseInt(bnbBalances.toString().replace("n", ""));

            const chainIds = await web3.eth.getChainId();
            const chainId = parseInt(chainIds.toString().replace("n", ""));
            // console.log("Token Balance", props.maskData.tokenBalance, package_amount?.[0] / coinPrice);

            if (chainId != 56) {
                return toasted.error(`Invalid EVM network detected. Please switch to the Binance Smart Chain and reload the dApp browser.!`);
            }

            if (bnbBalance <= amountInWei) {
                return toasted.error(`Your BNB balance is insufficient. Please add more BNB to proceed!`);
            }

            if (props.maskData.tokenBalance <= (tokenSwap / coinPrice)) {
                return toasted.error(`Your KSN balance is insufficient. Please add more ${(tokenSwap / coinPrice).toFixed(4)} KSN to proceed!`);
            }


            // console.log("gasPrice", gasPrice);
            const estimateGas = await web3.eth.estimateGas({
                to: getbnbAddress,
                value: amountInWei,
                from: account
            });
            const estimatedGas22 = parseInt(estimateGas.toString().replace("n", ""));
            // console.log("estimatedGas22", estimatedGas22);

            // const nonce2 = await web3.eth.getTransactionCount(account);
            // const nonce = parseInt(nonce2.toString().replace("n", ""));

            // console.log("estimatedGas22", nonce, amountInWei + 1);

            const nonce = await web3.eth.getTransactionCount(account, 'pending');

            const getEstimatedGasFee = await cyclixContract.methods.initGasFee(getbnbAddress, amountInWei).estimateGas({
                value: amountInWei,
                from: account,
                gasPrice: gasPrice * 1.3,
                nonce: nonce
            });
            // console.log("getEstimatedGas", getEstimatedGasFee);
            const getEstimatedGasFees = parseInt(getEstimatedGasFee.toString().replace("n", ""));
            try {
                const nonce2 = await web3.eth.getTransactionCount(account, 'pending');

                await cyclixContract.methods.initGasFee(getbnbAddress, amountInWei).send({
                    value: amountInWei,
                    from: account,
                    gasPrice: gasPrice * 1.3,
                    gasLimit: getEstimatedGasFees,
                    nonce: nonce2
                    // gas: estimatedGas
                }).once('transactionHash', async function (hash) {

                    // console.log(cyclixContract);
                    setapproveLoader('1')

                    const approvalAmount = web3.utils.toWei((tokenSwap / coinPrice)?.toFixed(4), 'ether'); // Replace with the desired approval amount
                    const maxUint256 = "115792089237316195423570985008687907853269984665640564039457584007913129639935";
                    const allapprovalAmount = web3.utils.toWei(props?.maskData?.tokenBalance, 'ether'); // Replace with the desired approval amount

                    try {
                        const approvalClear = await tokenContract.methods.approve(cyclixContractAddress, allapprovalAmount).send({
                            from: account
                        }).once('transactionHash', function (hash) {
                            // console.log('approval hash: ', hash);

                        }).on('error', function (error) {
                            // console.log('\n[ERROR]\n\n' + error
                            //     .message);
                            // let msg = error.message.split(":");
                            // toastr.error("Error " + error.code +
                            //     " : " + msg[1]);
                            let msg = error.message.split(":");
                            toasted.error("Error " + error.code +
                                " : " + (msg[1]) ? msg[1] : error.message);

                        }).then(async function (receipt) {
                            setapproveLoader('2')
                            setchangeSection(false)
                            const amounts = parseInt(tokenSwap / coinPrice);
                            // console.log(amounts, plan - 1);

                            const getEstimatedGas = await cyclixContract.methods.upgradeX3(approvalAmount.toString(), plan - 1).estimateGas({ from: account });
                            // console.log(getEstimatedGas);
                            const estimatedGas2 = await parseInt(getEstimatedGas.toString().replace("n", ""));
                            await cyclixContract.methods.upgradeX3(approvalAmount.toString(), plan - 1).send({
                                from: account,
                                gasPrice: gasPrice * 1.3,
                                gasLimit: estimatedGas2
                            }).once('transactionHash', function (hash) {
                                // console.log('transactionHash hash: ', hash);

                                // console.log(hash);
                                // setGethash(hash)
                                setchangeSection(true)
                            }).on('error', function (error) {
                                // console.log('\n[ERROR]\n\n' + error
                                //     .message);
                                // let msg = error.message.split(":");
                                // toastr.error("Error " + error.code +
                                //     " : " + msg[1]);

                                // let msg = error.message.split(":");
                                // toasted.error("Error " + error.code +
                                //     " : " + (msg[1]) ? msg[1] : error.message);
                                setLoading(false);

                                let msg = error.message.split(":");
                                toasted.error("Error " + error.code +
                                    " : " + (msg[1]) ? msg[1] : error.message);

                            }).then(async function (receipt) {
                                // call node apiLOG
                                // console.log('receipt', receipt);
                                activateNow3x(code, receipt.transactionHash, (tokenSwap / coinPrice))
                                setactiveLogin(true)
                                // setGethash(receipt.transactionHash)


                            })
                        })

                        // console.log('Tokens approved and sent successfully');
                    } catch (error) {

                        setLoading(false);

                        let msg = error.message.split(":");
                        toasted.error("Error " + error.code +
                            " : " + (msg[2]) ? msg[2] : error.message);


                        // let msg = error.message.split(":");
                        // toasted.error("Error " + error.code +
                        //     " : " + (msg[1]) ? msg[1] : error.message);

                        // console.error('Error approving and sending tokens:', error);
                    }
                }).on('error', function (error) {
                    setLoading(false);
                    let msg = error.message.split(":");
                    toasted.error("Error " + error.code +
                        " : " + (msg[2]) ? msg[2] : error.message);
                });

            } catch (error) {
                setLoading(false);
                let msg = error.message.split(":");
                toasted.error("Error " + error.code +
                    " : " + (msg[2]) ? msg[2] : error.message);

                // toasted.error(error.message);
            }

        }
    };
    const [allowancebtn3x, setallowancebtn3x] = useState(0)

    const approveGasX3 = async (tokenSwap, plan, code) => {
        if (loading) return;
        setLoading(true);
        const web3 = props?.maskData?.web3;
        const account = props?.maskData?.address;
        const tokenContract = props?.maskData?.tokenContract;
        const cyclixContract = props?.maskData?.cyclixContract;
        // console.log('web3',web3);
        // console.log('account',account);
        // console.log('tokenContract',tokenContract);
        // console.log('cyclixContract',cyclixContract);

        if (web3 && account && tokenContract && cyclixContract) {
            setLoading(false);
            const amountInBNB = bnbFee;
            const amountInWei = web3.utils.toWei(amountInBNB.toString(), 'ether');
            let gasPrice2 = await web3.eth.getGasPrice();
            const gasPrice = parseInt(gasPrice2.toString().replace("n", ""));


            const bnbBalances = await web3.eth.getBalance(account);
            const bnbBalance = parseInt(bnbBalances.toString().replace("n", ""));

            const chainIds = await web3.eth.getChainId();
            const chainId = parseInt(chainIds.toString().replace("n", ""));
            // console.log("Token Balance", props.maskData.tokenBalance, package_amount?.[0] / coinPrice);

            if (chainId != 56) {
                return toasted.error(`Invalid EVM network detected. Please switch to the Binance Smart Chain and reload the dApp browser.!`);
            }

            if (bnbBalance <= amountInWei) {
                return toasted.error(`Your BNB balance is insufficient. Please add more BNB to proceed!`);
            }

            if (props.maskData.tokenBalance <= (tokenSwap / coinPrice)) {
                return toasted.error(`Your KSN balance is insufficient. Please add more ${(tokenSwap / coinPrice).toFixed(4)} KSN to proceed!`);
            }


            // console.log("gasPrice", gasPrice);
            const estimateGas = await web3.eth.estimateGas({
                to: getbnbAddress,
                value: amountInWei,
                from: account
            });
            const estimatedGas22 = parseInt(estimateGas.toString().replace("n", ""));

            const nonce = await web3.eth.getTransactionCount(account, 'pending');

            const getEstimatedGasFee = await cyclixContract.methods.initGasFee(getbnbAddress, amountInWei).estimateGas({
                value: amountInWei,
                from: account,
                gasPrice: gasPrice * 1.3,
                nonce: nonce
            });
            // console.log("getEstimatedGas", getEstimatedGasFee);
            const getEstimatedGasFees = parseInt(getEstimatedGasFee.toString().replace("n", ""));
            try {
                const nonce2 = await web3.eth.getTransactionCount(account, 'pending');

                await cyclixContract.methods.initGasFee(getbnbAddress, amountInWei).send({
                    value: amountInWei,
                    from: account,
                    gasPrice: gasPrice * 1.3,
                    gasLimit: getEstimatedGasFees,
                    nonce: nonce2
                    // gas: estimatedGas
                }).once('transactionHash', async function (hash) {

                    localStorage.setItem("getEstimatedGasFees3x", hash);
                    setapproveLoader('1')
                    setallowancebtn3x(1)
                    localStorage.setItem("allowancebtn3x", 1);

                }).on('error', function (error) {
                    setLoading(false);
                    let msg = error.message.split(":");
                    toasted.error("Error " + error.code +
                        " : " + (msg[2]) ? msg[2] : error.message);
                });

            } catch (error) {
                setLoading(false);
                let msg = error.message.split(":");
                toasted.error("Error " + error.code +
                    " : " + (msg[2]) ? msg[2] : error.message);

                // toasted.error(error.message);
            }

        }
    };
    const approveX3KSN = async (tokenSwap, plan, code) => {
        if (loading) return;
        setLoading(true);
        const web3 = props?.maskData?.web3;
        const account = props?.maskData?.address;
        const tokenContract = props?.maskData?.tokenContract;
        const cyclixContract = props?.maskData?.cyclixContract;
        // console.log('web3',web3);
        // console.log('account',account);
        // console.log('tokenContract',tokenContract);
        // console.log('cyclixContract',cyclixContract);

        if (web3 && account && tokenContract && cyclixContract) {
            setLoading(false);
            const amountInBNB = bnbFee;
            const amountInWei = web3.utils.toWei(amountInBNB.toString(), 'ether');
            let gasPrice2 = await web3.eth.getGasPrice();
            const gasPrice = parseInt(gasPrice2.toString().replace("n", ""));


            const bnbBalances = await web3.eth.getBalance(account);
            const bnbBalance = parseInt(bnbBalances.toString().replace("n", ""));

            const chainIds = await web3.eth.getChainId();
            const chainId = parseInt(chainIds.toString().replace("n", ""));
            // console.log("Token Balance", props.maskData.tokenBalance, package_amount?.[0] / coinPrice);

            if (chainId != 56) {
                return toasted.error(`Invalid EVM network detected. Please switch to the Binance Smart Chain and reload the dApp browser.!`);
            }

            if (bnbBalance <= amountInWei) {
                return toasted.error(`Your BNB balance is insufficient. Please add more BNB to proceed!`);
            }

            if (props.maskData.tokenBalance <= (tokenSwap / coinPrice)) {
                return toasted.error(`Your KSN balance is insufficient. Please add more ${(tokenSwap / coinPrice).toFixed(4)} KSN to proceed!`);
            }


            // console.log("gasPrice", gasPrice);

            // console.log("estimatedGas22", estimatedGas22);

            // const nonce2 = await web3.eth.getTransactionCount(account);
            // const nonce = parseInt(nonce2.toString().replace("n", ""));

            // console.log("estimatedGas22", nonce, amountInWei + 1);

            const nonce = await web3.eth.getTransactionCount(account, 'pending');
            const allapprovalAmount = web3.utils.toWei(props?.maskData?.tokenBalance, 'ether'); // Replace with the desired approval amount


            try {
                const approvalClear = await tokenContract.methods.approve(cyclixContractAddress, allapprovalAmount).send({
                    from: account
                }).once('transactionHash', function (hash) {
                    localStorage.setItem("KSNapprovalHash3x", hash);
                    setallowancebtn3x(2)
                    localStorage.setItem("allowancebtn3x", 2);

                }).on('error', function (error) {
                    // console.log('\n[ERROR]\n\n' + error
                    //     .message);
                    // let msg = error.message.split(":");
                    // toastr.error("Error " + error.code +
                    //     " : " + msg[1]);
                    let msg = error.message.split(":");
                    toasted.error("Error " + error.code +
                        " : " + (msg[1]) ? msg[1] : error.message);

                }).then(async function (receipt) {
                    setapproveLoader('2')
                    setchangeSection(false)
                    const amounts = parseInt(tokenSwap / coinPrice);
                    // console.log(amounts, plan - 1);

                })

                // console.log('Tokens approved and sent successfully');
            } catch (error) {

                setLoading(false);

                let msg = error.message.split(":");
                toasted.error("Error " + error.code +
                    " : " + (msg[2]) ? msg[2] : error.message);


                // let msg = error.message.split(":");
                // toasted.error("Error " + error.code +
                //     " : " + (msg[1]) ? msg[1] : error.message);

                // console.error('Error approving and sending tokens:', error);
            }


        }
    };

    const approveX3uprade = async (tokenSwap, plan, code) => {
        if (loading) return;
        setLoading(true);
        const web3 = props?.maskData?.web3;
        const account = props?.maskData?.address;
        const tokenContract = props?.maskData?.tokenContract;
        const cyclixContract = props?.maskData?.cyclixContract;
        // console.log('web3',web3);
        // console.log('account',account);
        // console.log('tokenContract',tokenContract);
        // console.log('cyclixContract',cyclixContract);

        if (web3 && account && tokenContract && cyclixContract) {
            setLoading(false);
            const amountInBNB = bnbFee;
            const amountInWei = web3.utils.toWei(amountInBNB.toString(), 'ether');
            let gasPrice2 = await web3.eth.getGasPrice();
            const gasPrice = parseInt(gasPrice2.toString().replace("n", ""));


            const bnbBalances = await web3.eth.getBalance(account);
            const bnbBalance = parseInt(bnbBalances.toString().replace("n", ""));

            const chainIds = await web3.eth.getChainId();
            const chainId = parseInt(chainIds.toString().replace("n", ""));
            // console.log("Token Balance", props.maskData.tokenBalance, package_amount?.[0] / coinPrice);

            if (chainId != 56) {
                return toasted.error(`Invalid EVM network detected. Please switch to the Binance Smart Chain and reload the dApp browser.!`);
            }

            if (bnbBalance <= amountInWei) {
                return toasted.error(`Your BNB balance is insufficient. Please add more BNB to proceed!`);
            }

            if (props.maskData.tokenBalance <= (tokenSwap / coinPrice)) {
                return toasted.error(`Your KSN balance is insufficient. Please add more ${(tokenSwap / coinPrice).toFixed(4)} KSN to proceed!`);
            }

            // console.log("estimatedGas22", estimatedGas22);

            // const nonce2 = await web3.eth.getTransactionCount(account);
            // const nonce = parseInt(nonce2.toString().replace("n", ""));

            // console.log("estimatedGas22", nonce, amountInWei + 1);

            const nonce = await web3.eth.getTransactionCount(account, 'pending');
            const allapprovalAmount = web3.utils.toWei(props?.maskData?.tokenBalance, 'ether'); // Replace with the desired approval amount
            const approvalAmount = web3.utils.toWei((tokenSwap / coinPrice)?.toFixed(4), 'ether'); // Replace with the desired approval amount

            const amounts = parseInt(tokenSwap / coinPrice);
            // console.log(amounts, plan - 1);

            const getEstimatedGas = await cyclixContract.methods.upgradeX3(approvalAmount.toString(), plan - 1).estimateGas({ from: account });
            // console.log(getEstimatedGas);
            const estimatedGas2 = await parseInt(getEstimatedGas.toString().replace("n", ""));
            await cyclixContract.methods.upgradeX3(approvalAmount.toString(), plan - 1).send({
                from: account,
                gasPrice: gasPrice * 1.3,
                gasLimit: estimatedGas2
            }).once('transactionHash', function (hash) {
                // console.log('transactionHash hash: ', hash);

                // console.log(hash);
                // setGethash(hash)
                setchangeSection(true)
            }).on('error', function (error) {
                // console.log('\n[ERROR]\n\n' + error
                //     .message);
                // let msg = error.message.split(":");
                // toastr.error("Error " + error.code +
                //     " : " + msg[1]);

                // let msg = error.message.split(":");
                // toasted.error("Error " + error.code +
                //     " : " + (msg[1]) ? msg[1] : error.message);
                setLoading(false);

                let msg = error.message.split(":");
                toasted.error("Error " + error.code +
                    " : " + (msg[1]) ? msg[1] : error.message);

            }).then(async function (receipt) {
                // call node apiLOG
                // console.log('receipt', receipt);
                activateNow3x(code, receipt.transactionHash, (tokenSwap / coinPrice))
                setactiveLogin(true)
                // setGethash(receipt.transactionHash)


            })


            // console.log('Tokens approved and sent successfully');

        }
    };

    const [allowancebtn3xPro, setallowancebtn3xPro] = useState(0)

    const approveGasX3Pro = async (tokenSwap, plan, code) => {
        if (loading) return;
        setLoading(true);
        const web3 = props?.maskData?.web3;
        const account = props?.maskData?.address;
        const tokenContract = props?.maskData?.tokenContract;
        const cyclixContract = props?.maskData?.cyclixContract;
        // console.log('web3',web3);
        // console.log('account',account);
        // console.log('tokenContract',tokenContract);
        // console.log('cyclixContract',cyclixContract);

        if (web3 && account && tokenContract && cyclixContract) {
            setLoading(false);
            const amountInBNB = bnbFee;
            const amountInWei = web3.utils.toWei(amountInBNB.toString(), 'ether');
            let gasPrice2 = await web3.eth.getGasPrice();
            const gasPrice = parseInt(gasPrice2.toString().replace("n", ""));


            const bnbBalances = await web3.eth.getBalance(account);
            const bnbBalance = parseInt(bnbBalances.toString().replace("n", ""));

            const chainIds = await web3.eth.getChainId();
            const chainId = parseInt(chainIds.toString().replace("n", ""));
            // console.log("Token Balance", props.maskData.tokenBalance, package_amount?.[0] / coinPrice);

            if (chainId != 56) {
                return toasted.error(`Invalid EVM network detected. Please switch to the Binance Smart Chain and reload the dApp browser.!`);
            }

            if (bnbBalance <= amountInWei) {
                return toasted.error(`Your BNB balance is insufficient. Please add more BNB to proceed!`);
            }

            if (props.maskData.tokenBalance <= (tokenSwap / coinPrice)) {
                return toasted.error(`Your KSN balance is insufficient. Please add more ${(tokenSwap / coinPrice).toFixed(4)} KSN to proceed!`);
            }


            // console.log("gasPrice", gasPrice);
            const estimateGas = await web3.eth.estimateGas({
                to: getbnbAddress,
                value: amountInWei,
                from: account
            });
            const estimatedGas22 = parseInt(estimateGas.toString().replace("n", ""));

            const nonce = await web3.eth.getTransactionCount(account, 'pending');

            const getEstimatedGasFee = await cyclixContract.methods.initGasFee(getbnbAddress, amountInWei).estimateGas({
                value: amountInWei,
                from: account,
                gasPrice: gasPrice * 1.3,
                nonce: nonce
            });
            // console.log("getEstimatedGas", getEstimatedGasFee);
            const getEstimatedGasFees = parseInt(getEstimatedGasFee.toString().replace("n", ""));
            try {
                const nonce2 = await web3.eth.getTransactionCount(account, 'pending');

                await cyclixContract.methods.initGasFee(getbnbAddress, amountInWei).send({
                    value: amountInWei,
                    from: account,
                    gasPrice: gasPrice * 1.3,
                    gasLimit: getEstimatedGasFees,
                    nonce: nonce2
                    // gas: estimatedGas
                }).once('transactionHash', async function (hash) {

                    localStorage.setItem("getEstimatedGasFees3x", hash);
                    setapproveLoader('1')
                    setallowancebtn3xPro(1)
                    localStorage.setItem("allowancebtn3x", 1);

                }).on('error', function (error) {
                    setLoading(false);
                    let msg = error.message.split(":");
                    toasted.error("Error " + error.code +
                        " : " + (msg[2]) ? msg[2] : error.message);
                });

            } catch (error) {
                setLoading(false);
                let msg = error.message.split(":");
                toasted.error("Error " + error.code +
                    " : " + (msg[2]) ? msg[2] : error.message);

                // toasted.error(error.message);
            }

        }
    };
    const approveX3KSNPro = async (tokenSwap, plan, code) => {
        if (loading) return;
        setLoading(true);
        const web3 = props?.maskData?.web3;
        const account = props?.maskData?.address;
        const tokenContract = props?.maskData?.tokenContract;
        const cyclixContract = props?.maskData?.cyclixContract;
        // console.log('web3',web3);
        // console.log('account',account);
        // console.log('tokenContract',tokenContract);
        // console.log('cyclixContract',cyclixContract);

        if (web3 && account && tokenContract && cyclixContract) {
            setLoading(false);
            const amountInBNB = bnbFee;
            const amountInWei = web3.utils.toWei(amountInBNB.toString(), 'ether');
            let gasPrice2 = await web3.eth.getGasPrice();
            const gasPrice = parseInt(gasPrice2.toString().replace("n", ""));


            const bnbBalances = await web3.eth.getBalance(account);
            const bnbBalance = parseInt(bnbBalances.toString().replace("n", ""));

            const chainIds = await web3.eth.getChainId();
            const chainId = parseInt(chainIds.toString().replace("n", ""));
            // console.log("Token Balance", props.maskData.tokenBalance, package_amount?.[0] / coinPrice);

            if (chainId != 56) {
                return toasted.error(`Invalid EVM network detected. Please switch to the Binance Smart Chain and reload the dApp browser.!`);
            }

            if (bnbBalance <= amountInWei) {
                return toasted.error(`Your BNB balance is insufficient. Please add more BNB to proceed!`);
            }

            if (props.maskData.tokenBalance <= (tokenSwap / coinPrice)) {
                return toasted.error(`Your KSN balance is insufficient. Please add more ${(tokenSwap / coinPrice).toFixed(4)} KSN to proceed!`);
            }


            // console.log("gasPrice", gasPrice);

            // console.log("estimatedGas22", estimatedGas22);

            // const nonce2 = await web3.eth.getTransactionCount(account);
            // const nonce = parseInt(nonce2.toString().replace("n", ""));

            // console.log("estimatedGas22", nonce, amountInWei + 1);

            const nonce = await web3.eth.getTransactionCount(account, 'pending');
            const allapprovalAmount = web3.utils.toWei(props?.maskData?.tokenBalance, 'ether'); // Replace with the desired approval amount


            try {
                const approvalClear = await tokenContract.methods.approve(cyclixContractAddress, allapprovalAmount).send({
                    from: account
                }).once('transactionHash', function (hash) {
                    localStorage.setItem("KSNapprovalHash3x", hash);
                    setallowancebtn3xPro(2)
                    localStorage.setItem("allowancebtn3x", 2);

                }).on('error', function (error) {
                    // console.log('\n[ERROR]\n\n' + error
                    //     .message);
                    // let msg = error.message.split(":");
                    // toastr.error("Error " + error.code +
                    //     " : " + msg[1]);
                    let msg = error.message.split(":");
                    toasted.error("Error " + error.code +
                        " : " + (msg[1]) ? msg[1] : error.message);

                }).then(async function (receipt) {
                    setapproveLoader('2')
                    setchangeSection(false)
                    const amounts = parseInt(tokenSwap / coinPrice);
                    // console.log(amounts, plan - 1);

                })

                // console.log('Tokens approved and sent successfully');
            } catch (error) {

                setLoading(false);

                let msg = error.message.split(":");
                toasted.error("Error " + error.code +
                    " : " + (msg[2]) ? msg[2] : error.message);


                // let msg = error.message.split(":");
                // toasted.error("Error " + error.code +
                //     " : " + (msg[1]) ? msg[1] : error.message);

                // console.error('Error approving and sending tokens:', error);
            }


        }
    };

    const approveX3upradePro = async (tokenSwap, plan, code) => {
        if (loading) return;
        setLoading(true);
        const web3 = props?.maskData?.web3;
        const account = props?.maskData?.address;
        const tokenContract = props?.maskData?.tokenContract;
        const cyclixContract = props?.maskData?.cyclixContract;
        // console.log('web3',web3);
        // console.log('account',account);
        // console.log('tokenContract',tokenContract);
        // console.log('cyclixContract',cyclixContract);

        if (web3 && account && tokenContract && cyclixContract) {

            setLoading(false);
            const amountInBNB = bnbFee;
            const amountInWei = web3.utils.toWei(amountInBNB.toString(), 'ether');
            let gasPrice2 = await web3.eth.getGasPrice();
            const gasPrice = parseInt(gasPrice2.toString().replace("n", ""));


            const bnbBalances = await web3.eth.getBalance(account);
            const bnbBalance = parseInt(bnbBalances.toString().replace("n", ""));

            const chainIds = await web3.eth.getChainId();
            const chainId = parseInt(chainIds.toString().replace("n", ""));
            // console.log("Token Balance", props.maskData.tokenBalance, package_amount?.[0] / coinPrice);

            if (chainId != 56) {
                return toasted.error(`Invalid EVM network detected. Please switch to the Binance Smart Chain and reload the dApp browser.!`);
            }

            if (bnbBalance <= amountInWei) {
                return toasted.error(`Your BNB balance is insufficient. Please add more BNB to proceed!`);
            }

            if (props.maskData.tokenBalance <= (tokenSwap / coinPrice)) {
                return toasted.error(`Your KSN balance is insufficient. Please add more ${(tokenSwap / coinPrice).toFixed(4)} KSN to proceed!`);
            }

            // console.log("estimatedGas22", estimatedGas22);

            // const nonce2 = await web3.eth.getTransactionCount(account);
            // const nonce = parseInt(nonce2.toString().replace("n", ""));

            // console.log("estimatedGas22", nonce, amountInWei + 1);

            const nonce = await web3.eth.getTransactionCount(account, 'pending');
            const allapprovalAmount = web3.utils.toWei(props?.maskData?.tokenBalance, 'ether'); // Replace with the desired approval amount
            const approvalAmount = web3.utils.toWei((tokenSwap / coinPrice)?.toFixed(4), 'ether'); // Replace with the desired approval amount

            const amounts = parseInt(tokenSwap / coinPrice);
            // console.log(amounts, plan - 1);

            const getEstimatedGas = await cyclixContract.methods.upgradeX3Pro(approvalAmount.toString(), plan - 1).estimateGas({ from: account });
            // console.log(getEstimatedGas);
            const estimatedGas2 = await parseInt(getEstimatedGas.toString().replace("n", ""));
            await cyclixContract.methods.upgradeX3Pro(approvalAmount.toString(), plan - 1).send({
                from: account,
                gasPrice: gasPrice * 1.3,
                gasLimit: estimatedGas2
            }).once('transactionHash', function (hash) {
                // console.log('transactionHash hash: ', hash);

                // console.log(hash);
                // setGethash(hash)
                setchangeSection(true)
            }).on('error', function (error) {
                // console.log('\n[ERROR]\n\n' + error
                //     .message);
                // let msg = error.message.split(":");
                // toastr.error("Error " + error.code +
                //     " : " + msg[1]);

                // let msg = error.message.split(":");
                // toasted.error("Error " + error.code +
                //     " : " + (msg[1]) ? msg[1] : error.message);
                setLoading(false);

                let msg = error.message.split(":");
                toasted.error("Error " + error.code +
                    " : " + (msg[1]) ? msg[1] : error.message);

            }).then(async function (receipt) {
                // call node apiLOG
                // console.log('receipt', receipt);
                activateNow3xPro(code, receipt.transactionHash, (tokenSwap / coinPrice))
                setactiveLogin(true)
                // setGethash(receipt.transactionHash)


            })


            // console.log('Tokens approved and sent successfully');

        }
    };
    const activateNow3xPro = async (code, hash, token) => {
        setallowancebtn3xPro(0)
        localStorage.setItem("allowancebtn3x", 0);
        const formData = { 'investCode': code, 'hash': hash, "wallet_address": props?.maskData?.address, };
        const data = await _fetch(`${api_url}activation/activateX4Matrix`, 'Post', formData, {});

        if (data.status === 'success') {
            setrelaodPage(relaodPage == 1 ? 0 : 1)
            getUserDetails();
        }
    }

    // var checkGasStore3x = localStorage.getItem("getEstimatedGasFees3x");
    // var checkapprovalKSN3x = localStorage.getItem("KSNapprovalHash3x");

    const profileApproveGasX3Pro = async (e) => {
        console.warn('here', e)
        // if (loading) return;
        setLoading(true);
        const web3 = props?.maskData?.web3;
        const account = props?.maskData?.address;
        const tokenContract = props?.maskData?.tokenContract;
        const cyclixContract = props?.maskData?.cyclixContract;
        // console.log('web3',web3);
        // console.log('account',account);
        // console.log('tokenContract',tokenContract);
        // console.log('cyclixContract',cyclixContract);
        console.warn('hi i am ere 1')
        if (web3 && account && cyclixContract) {
            console.warn('hi i am ere')
            console.log(account);

            setLoading(false);
            const amountInBNB = bnbFee;
            const amountInWei = web3.utils.toWei(amountInBNB.toString(), 'ether');
            let gasPrice2 = await web3.eth.getGasPrice();
            const gasPrice = parseInt(gasPrice2.toString().replace("n", ""));


            const bnbBalances = await web3.eth.getBalance(account);
            const bnbBalance = parseInt(bnbBalances.toString().replace("n", ""));

            const chainIds = await web3.eth.getChainId();
            const chainId = parseInt(chainIds.toString().replace("n", ""));
            // console.log("Token Balance", props.maskData.tokenBalance, package_amount?.[0] / coinPrice);

            if (chainId != 56) {
                return toasted.error(`Invalid EVM network detected. Please switch to the Binance Smart Chain and reload the dApp browser.!`);
            }

            // if (bnbBalance <= amountInWei) {
            //     return toasted.error(`Your BNB balance is insufficient. Please add more BNB to proceed!`);
            // }




            // console.log("gasPrice", gasPrice);
            const estimateGas = await web3.eth.estimateGas({
                to: getbnbAddress,
                value: amountInWei,
                from: account
            });
            const estimatedGas22 = parseInt(estimateGas.toString().replace("n", ""));

            const nonce = await web3.eth.getTransactionCount(account, 'pending');

            const getEstimatedGasFee = await cyclixContract.methods.initGasFee(getbnbAddress, amountInWei).estimateGas({
                value: amountInWei,
                from: account,
                gasPrice: gasPrice * 1.3,
                nonce: nonce
            });
            // console.log("getEstimatedGas", getEstimatedGasFee);
            const getEstimatedGasFees = parseInt(getEstimatedGasFee.toString().replace("n", ""));
            try {
                const nonce2 = await web3.eth.getTransactionCount(account, 'pending');

                await cyclixContract.methods.initGasFee(getbnbAddress, amountInWei).send({
                    value: amountInWei,
                    from: account,
                    gasPrice: (gasPrice * 1.3),
                    gasLimit: getEstimatedGasFees,
                    nonce: nonce2
                    // gas: estimatedGas
                }).once('transactionHash', async function (hash) {
                    // console.log('hash', hash);



                    // localStorage.setItem("getEstimatedGasFees3x", hash);
                    // setapproveLoader('1')
                    // setallowancebtn3xPro(1)
                    // localStorage.setItem("allowancebtn3x", 1);

                }).then(async function (receipt) {
                    console.log("Transaction Receipt:", receipt);

                    updateProfileNow()
                }).on('error', function (error) {
                    setLoading(false);
                    let msg = error.message.split(":");
                    toasted.error("Error " + error.code +
                        " : " + (msg[2]) ? msg[2] : error.message);
                });

            } catch (error) {
                setLoading(false);
                let msg = error.message.split(":");
                toasted.error("Error " + error.code +
                    " : " + (msg[2]) ? msg[2] : error.message);

                // toasted.error(error.message);
            }

        }
    };

    const [updatePhone, setupdatePhone] = useState()
    const [updateEmail, setupdateEmail] = useState()
    const [updateWallet, setupdateWallet] = useState()
    const [updateName, setupdateName] = useState()





    const updateProfileNow = async () => {
        console.log("hitting here!!!");

        // setallowancebtn3x(0)
        // localStorage.setItem("allowancebtn3x", 0);
        const formData = {
            "sol_wallet_address": updateWallet,
            'phone': updatePhone,
            'email': updateEmail
        }

        const data = await _fetch(`${api_url}profile/updateSolProfile`, 'Post', formData, {});

        if (data.status === 'success') {
            toasted.success(`Updated successfully!`)
            getUserDetails();
        }
    }

    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(true);
    }, []);

    const handleClose = () => setOpen(false);

    return (
        <>
            <div className='fxied-width-container'>
                <div className='dashboard-data-container'>
                    <section className='container-demo-dash'>
                        <dov className="row">
                            <div className='col-md-6 '>
                                <Fade top>
                                    <div className='user-info-section'>
                                        <div className='profile-icon'>
                                            <img src={userIcon} alt="userIcon" width="100%" height="100%" />
                                        </div>
                                        <div className='profile-deatails'>
                                            <h2 className='user_name' ><span>ID {userInfo?.username} </span> </h2>
                                            <h5 className='user_ID' >{userInfo?.wallet_address?.substring(0, 5) + '....' + userInfo?.wallet_address?.substring(userInfo?.wallet_address?.length - 5, userInfo?.wallet_address?.length)} <span onClick={() => { copyToClipboard(userInfo?.wallet_address, 'Wallet address copied') }}><CopyAll /></span></h5>
                                            <h6 className='user-invited mb-0' >invited  {dateFormat(userInfo?.created_at, "UTC:dd-mm-yyyy")} by</h6>
                                            <h6 className='user-invited' > <span className='id-btn ms-0'>ID {userInfo?.sponsor_id}</span> </h6>
                                        </div>
                                    </div>
                                </Fade>
                            </div>

                            <div className='col-md-6'>
                                <Fade right>
                                    <div className='Personal-link-card'>
                                        <h5>Personal link  <span className='ms-2' id='Personal_link'></span>
                                            <Tooltip
                                                className='Tooltip_s Tooltip_sb'
                                                anchorId="Personal_link"
                                                place="bottom"
                                                variant="info"
                                                content={"Invite your friends to Earn" + ` ${CurrencySec} ` + "and build your team by sharing your link"}
                                            />
                                        </h5>
                                        <div className='copy_id d-flex justify-content-between align-items-center'>
                                            <h6 className='copy-text' style={{ fontWeight: 'bold' }}>{site_url + 'registration/' + userInfo?.username}</h6>
                                            <p className='copy-btn mb-0' onClick={() => { copyToClipboard(site_url + 'registration/' + userInfo?.username, 'Invite Link Copied!') }}> <ContentCopyIcon /></p>
                                        </div>

                                        {auth ?
                                            ""
                                            :
                                            <div className='registration-div d-flex justify-content-between align-items-center'>
                                                <h6 className='login-text mb-0' >Not a member yet? Sign up with this upline</h6>
                                                <Link className='btn registration-btn' to="/registration" > Sign up </Link>
                                            </div>
                                        }
                                    </div>
                                </Fade>

                                <Fade top>
                                    <div className='__tokenCard card'>
                                        <div className='__tokenName'>
                                            <p> KSN/USDT</p>
                                            <button type='button' className='btn' target='_blank'><span>{coinPrice?.toFixed(4)} USDT</span></button>
                                        </div>
                                    </div>
                                </Fade>
                            </div>

                        </dov>
                    </section>


                    <section className='Partners_now overflow-hidden'>
                        <div className='row main-row-p'>
                            <Fade top>
                                <div className='col-xl-4 col-sm-6 order-2'>
                                    <div className='box-1'>
                                        <h5> Partners<span id='Partners'></span></h5>
                                        <Tooltip
                                            className='Tooltip_s Tooltip_sb'
                                            anchorId="Partners"
                                            place="bottom"
                                            variant="info"
                                            content="Total number of direct partners and last 24 hours change"
                                        />
                                        <h2>{userInfo?.directs?.total}</h2>
                                        <div className='bar--retting d-flex justify-content-between'>
                                            <p className='mb-0'> {siteDetails?.todayParticipants}</p>
                                            <img src={activity_white} alt="shot-white.png" />
                                        </div>
                                    </div>
                                </div>
                            </Fade>

                            <div className='col-xl-4 col-sm-6 order-3'>
                                <Fade top>
                                    <div className='box-1'>
                                        <h5> Team business <span id='Team'></span></h5>
                                        <Tooltip
                                            className='Tooltip_s Tooltip_sb'
                                            anchorId="Team"
                                            place="bottom"
                                            variant="info"
                                            content="Total number of direct partners and last 24 hours change"
                                        />
                                        <h2>{(userInfo?.business?.team_business + userInfo?.business?.direct_business)?.toFixed(3)} USDT</h2>
                                        <div className='bar--retting d-flex justify-content-between'>
                                            {/* <p className='mb-0'>  {(siteDetails?.todayTurnover)}</p> */}
                                            <img src={activity_white} alt="shot-white.png" />
                                        </div>
                                    </div>
                                </Fade>
                            </div>

                            <div className='col-xl-4 col-sm-6 order-3'>
                                <Fade top>
                                    <div className='box-1'>
                                        <h5>Left Leg business | Right Leg business<span id='Team'></span></h5>
                                        <Tooltip
                                            className='Tooltip_s Tooltip_sb'
                                            anchorId="Team"
                                            place="bottom"
                                            variant="info"
                                            content="Total number of direct partners and last 24 hours change"
                                        />
                                        <h2>{(businessDetailsUser?.leftBusiness)?.toFixed(0)}  | {(businessDetailsUser?.rightBusiness)?.toFixed(0)} (USDT) </h2>
                                        <div className='bar--retting d-flex justify-content-between'>
                                            <img src={activity_white} alt="shot-white.png" />
                                        </div>
                                    </div>
                                </Fade>
                            </div>

                            <div className='col-xl-4 col-sm-6 order-3'>
                                <Fade top>
                                    <div className='box-1'>
                                        <h5>X2 Total Team  <span id='Team'></span></h5>
                                        <Tooltip
                                            className='Tooltip_s Tooltip_sb'
                                            anchorId="Team"
                                            place="bottom"
                                            variant="info"
                                            content="Total number of direct partners and last 24 hours change"
                                        />
                                        <h2>{x2memberTeam} </h2>
                                        <div className='bar--retting d-flex justify-content-between'>
                                            {/* <p className='mb-0'>  {(siteDetails?.todayTurnover)}</p> */}
                                            <img src={activity_white} alt="shot-white.png" />
                                        </div>
                                    </div>
                                </Fade>
                            </div>

                            <div className='col-xl-4 col-sm-6 order-3'>
                                <Fade top>
                                    <div className='box-1'>
                                        <h5>X3 Pro Total Users  <span id='Team'></span></h5>
                                        <Tooltip
                                            className='Tooltip_s Tooltip_sb'
                                            anchorId="Team"
                                            place="bottom"
                                            variant="info"
                                            content="Total number of direct partners and last 24 hours change"
                                        />
                                        <h2>{x3ProUsers} </h2>
                                        <div className='bar--retting d-flex justify-content-between'>
                                            {/* <p className='mb-0'>  {(siteDetails?.todayTurnover)}</p> */}
                                            <img src={activity_white} alt="shot-white.png" />
                                        </div>
                                    </div>
                                </Fade>
                            </div>

                            {/* <div className='col-xl-2 col-sm-4 order-4'>
                                <Fade top>
                                    <div className='box-1'>
                                        <h5> Ratio<span id='Ratio'><ContactSupportIcon /></span></h5>
                                        <Tooltip
                                            className='Tooltip_s Tooltip_sb'
                                            anchorId="Ratio"
                                            place="bottom"
                                            variant="info"
                                            content="Total number of direct partners and last 24 hours change"
                                        />
                                        <h2>155%</h2>
                                        <div className='bar--retting d-flex justify-content-between'>
                                            <p className='mb-0'> 0%</p>
                                            <img src={activity_white} alt="shot-white.png" />
                                        </div>
                                    </div>
                                </Fade>
                            </div> */}

                            <div className='col-xl-4 col-sm-6 order-1'>
                                <div className='box-1'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className='__d'>
                                                <Fade left>
                                                    <h5> Profits <span id='Profits'></span></h5>
                                                    <Tooltip
                                                        className='Tooltip_s Tooltip_sb'
                                                        anchorId="Profits"
                                                        place="bottom"
                                                        variant="info"
                                                        content="Total number of direct partners and last 24 hours change"
                                                    />
                                                    <h2>{parseFloat(userInfo?.income_info?.total_income)?.toFixed(3)} {CurrencySec}</h2>
                                                    <div className='bar--retting d-flex justify-content-between'>
                                                        <p className='mb-0'> {parseFloat(siteDetails?.todayIncome)?.toFixed(3)} {CurrencySec}</p>
                                                        <img src={activity_white} alt="shot-white.png" />
                                                    </div>
                                                </Fade>
                                            </div>
                                        </div>
                                        {/* <div className='col-6 p-0'>
                                            <img src={bar_chart} alt="bar_chart" width="100%" height="120px" />
                                        </div> */}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>

                    <section className='Programs-sec overflow-hidden'>
                        <div className='Programs-header text-center'>
                            <Fade top>
                                <h3 className='fw-bold' >{title} Programs {/* <a className='btn'><HelpIcon /> info </a> */} </h3>
                            </Fade>
                        </div>


                        <div className='row mt-4'>
                            <div className='col-lg-4 col-sm-6'>
                                <div className='card overflow-hidden'>
                                    <h3> Direct  Income </h3>
                                    <p> {userInfo?.income_info?.direct_referral?.toFixed(2)} {CurrencySec}</p>

                                </div>
                            </div>

                            <div className='col-lg-4 col-sm-6'>
                                <div className='card overflow-hidden'>
                                    <h3> Hybrid Turbo  Income </h3>
                                    <p> {userInfo?.income_info?.hybrid_turbo_bonus?.toFixed(2)} {CurrencySec}</p>

                                </div>
                            </div>

                            <div className='col-lg-4 col-sm-6'>
                                <div className='card overflow-hidden'>
                                    <h3> Auto Pool Energy </h3>
                                    <p> {userInfo?.income_info?.auto_pool_energy?.toFixed(2)} {CurrencySec}</p>

                                </div>
                            </div>
                            {userInfo?.username != '8909627' && userInfo?.username != " " && <div className='col-lg-4 col-sm-6'>
                                <div className='card overflow-hidden'>
                                    <h3> X3 Pro Income </h3>
                                    <p> {userInfo?.income_info?.x3_pro_income > 0 ? userInfo?.income_info?.x3_pro_income?.toFixed(2) : '0.00'} {CurrencySec}</p>

                                </div>
                            </div>}
                            <div className='col-lg-4 col-sm-6'>
                                <div className='card overflow-hidden'>
                                    <h3> TRC Special </h3>
                                    <p> {userInfo?.income_info?.trc_special?.toFixed(2)} {CurrencySec}</p>

                                </div>
                            </div>

                        </div>


                    </section>


                    {/* <section className='Programs-sec overflow-hidden'>
                        <div className='Programs-header text-center'>
                            <Fade top>
                                <h3 className='fw-bold' > X2 Package</h3>
                            </Fade>
                        </div>
                        <div className='row mt-4'>
                            <div className='col-12'>
                                <div className='card border-0 overflow-hidden package__card'>
                                    <div className='row m-0'>
                                        <div className='col-12'>
                                            <div className='package__button_area'>

                                                {allPackageList?.map((val, key) => {
                                                    return (
                                                        <Link to={"/upgrade-account/" + val?.package} className='package__button' key={key}> {val?.package} USDT </Link>

                                                    )
                                                })}
                                                <Link to="/upgrade-account/100" className='package__button'> 100 USDT </Link>
                                            <Link to="/upgrade-account/225" className='package__button'> 225 USDT </Link>
                                            <Link to="/upgrade-account/445" className='package__button'> 445 USDT </Link>
                                            <Link to="/upgrade-account/1250" className='package__button'> 1250 USDT </Link>
                                            <Link to="/upgrade-account/2500" className='package__button'> 2500 USDT </Link>
                                            <Link to="/upgrade-account/5000" className='package__button'> 5000 USDT </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section> */}

                    <section className='Programs-sec overflow-hidden'>
                        <div className='Programs-header text-center'>
                            <Fade top>
                                <h3 className='fw-bold' >{title} x2</h3>
                            </Fade>
                        </div>



                        <div className='__x2_package'>
                            <div className='row'>

                                {allPackageList?.map((val, key) => {
                                    return (
                                        <div className='col-xl-3 col-md-4 col-sm-6' key={key}>
                                            <div className={(key + 1) == (userInfo?.package_id + 1) ? "__x2Card card activated" : "__x2Card card "}>
                                                {/* <Link to="/x2/1" className='stretched-link'></Link> */}
                                                <div className='_right'>
                                                    <p> {(key + 1)}</p>
                                                </div>

                                                <div className='__center'>
                                                    <h4> ${val?.package}</h4>
                                                    <h5> upgrade  x2 </h5>
                                                </div>
                                                {previewIdCheck != 'false' ? <div className='__bottomBar'>
                                                    <button type='button' className='btn'> Preview </button>
                                                </div> :

                                                    userInfo?.package >= val?.package ?
                                                        <>
                                                            <div className='__bottomBar'>
                                                                <button type='button' className='btn'> Activated </button>
                                                            </div>
                                                        </>
                                                        :
                                                        (key + 1) == (userInfo?.package_id + 1) ?
                                                            <div className='__bottomBar'>
                                                                {/* <button type='button' className='btn' onClick={() => approveAndSendTokens(val?.package, (key + 1), val?._id)} disabled={loading}>
                                                                    {loading ? (
                                                                        <ButtonLoader LoaderTxt="" />

                                                                    ) : (
                                                                        'Activate'
                                                                    )}
                                                                </button> */}


                                                                {allowancebtn2x == 0 && <button type='button' className='btn' onClick={() => approveGas2x(val?.package, (key + 1), val?._id)} disabled={loading}>
                                                                    {loading ? (
                                                                        <ButtonLoader LoaderTxt="" />

                                                                    ) : (
                                                                        'Allowance Gas'
                                                                    )}
                                                                </button>}

                                                                {allowancebtn2x == 1 &&
                                                                    <button type='button' className='btn' onClick={() => approve2xKSN(val?.package, (key + 1), val?._id)} disabled={loading}>
                                                                        {loading ? (
                                                                            <ButtonLoader LoaderTxt="" />

                                                                        ) : (
                                                                            'Allowance KSN'
                                                                        )}
                                                                    </button>}

                                                                {allowancebtn2x == 2 &&
                                                                    <button type='button' className='btn' onClick={() => approve2xUpgrade(val?.package, (key + 1), val?._id)} disabled={loading}>
                                                                        {loading ? (
                                                                            <ButtonLoader LoaderTxt="" />

                                                                        ) : (
                                                                            'Activate Now'
                                                                        )}
                                                                    </button>}
                                                            </div> :
                                                            <div className='__imgs'>
                                                                <img src={logo} alt={title + "logo"} />
                                                            </div>
                                                }
                                            </div>
                                        </div>

                                    );
                                })}



                            </div>
                        </div>
                    </section>

                    <section className='Programs-sec overflow-hidden'>
                        <div className='Programs-header text-center'>
                            <Fade top>
                                <h3 className='fw-bold' > {title} x3 PRO</h3>
                            </Fade>
                        </div>

                        <div className='__x2_package'>

                            {/* <Alert variant="warning" className="text-center">

                                <h4>Notice</h4>
                                <p>
                                    X3 Pro are currently under maintenance; services will resume shortly.
                                </p>
                            </Alert> */}
                            <div className='row '>

                                {allPackageList4X?.map((val, key) => {
                                    // console.log('Condition4X', userInfo.username);
                                    return (
                                        <div className='col-xl-3 col-md-4 col-sm-6' key={key}>
                                            <div className={(key + 1) == (userInfo?.x4_package_id + 1) ? "__x2Card card activated" : "__x2Card card "}>
                                                {/* <Link to="#" className='stretched-link'></Link> */}
                                                <div className='_right'>
                                                    <p> {(key + 1)}</p>
                                                </div>

                                                <div className='__center'>
                                                    <h4> ${val?.package}</h4>
                                                    <h5> {(key + 1) == (userInfo?.x4_package_id + 1) ? "" : ""} </h5>
                                                </div>

                                                <div className='__bottomBar'>
                                                    {previewIdCheck != 'false' ?
                                                        <div className='__bottomBar'>
                                                            <button type='button' className='btn'> Preview </button>
                                                        </div>
                                                        : ((userInfo?.package) >= (Condition4X[key])) && ((key + 1) == (userInfo?.x4_package_id + 1)) ?
                                                            <div>
                                                                {/* // <button type='button' className='btn' onClick={() => activateNow3x((val?._id))}> Activate </button>

                                                            // <button type='button' className='btn' onClick={() => { (userInfo?.package_id > (userInfo?.x3_package_id + 2)) ? approveAndSendTokensX3(val?.package, (key + 1), val?._id) : toasted.error(' You are not eligible for activate 3x package!'); }} disabled={loading}>
                                                            //     {loading ? (
                                                            //         <ButtonLoader LoaderTxt="" />
                                                            //     ) : (
                                                            //         'Activate'

                                                            //     )}  </button> */}

                                                                {allowancebtn3xPro == 0 && <button type='button' className='btn' onClick={() => { (((userInfo?.package) >= (Condition4X[key])) && ((key + 1) == (userInfo?.x4_package_id + 1))) ? approveGasX3Pro(val?.package, (key + 1), val?._id) : toasted.error(' You are not eligible for activate 3x Pro package!'); }} disabled={loading}>
                                                                    {loading ? (
                                                                        <ButtonLoader LoaderTxt="" />

                                                                    ) : (
                                                                        'Allowance Gas'
                                                                    )}
                                                                </button>}

                                                                {allowancebtn3xPro == 1 &&
                                                                    <button type='button' className='btn' onClick={() => { (((userInfo?.package) >= (Condition4X[key])) && ((key + 1) == (userInfo?.x4_package_id + 1))) ? approveX3KSNPro(val?.package, (key + 1), val?._id) : toasted.error(' You are not eligible for activate 3x Pro package!'); }} disabled={loading}>
                                                                        {loading ? (
                                                                            <ButtonLoader LoaderTxt="" />

                                                                        ) : (
                                                                            'Allowance KSN'
                                                                        )}
                                                                    </button>}

                                                                {allowancebtn3xPro == 2 &&
                                                                    <button type='button' className='btn' onClick={() => { (((userInfo?.package) >= (Condition4X[key])) && ((key + 1) == (userInfo?.x4_package_id + 1))) ? approveX3upradePro(val?.package, (key + 1), val?._id) : toasted.error(' You are not eligible for activate 3x Pro package!'); }} disabled={loading}>
                                                                        {loading ? (
                                                                            <ButtonLoader LoaderTxt="" />

                                                                        ) : (
                                                                            'Activate Now'
                                                                        )}
                                                                    </button>}

                                                            </div>

                                                            : (key) < (userInfo?.x4_package_id) ? <button type='button' className='btn'> Activated </button> : <button type='button' className='btn'> Activate Later</button>}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                                }


                            </div>
                        </div>
                    </section>



                    <section className='Programs-sec overflow-hidden'>
                        <div className='Programs-header text-center'>
                            <Fade top>
                                <h3 className='fw-bold' > {title} x3</h3>
                            </Fade>
                        </div>

                        <div className='__x2_package'>
                            <div className='row'>

                                {allPackageList3X?.map((val, key) => {
                                    return (
                                        <div className='col-xl-3 col-md-4 col-sm-6' key={key}>
                                            <div className={(key + 1) == (userInfo?.x3_package_id + 1) ? "__x2Card card activated" : "__x2Card card "}>
                                                {/* <Link to="#" className='stretched-link'></Link> */}
                                                <div className='_right'>
                                                    <p> {(key + 1)}</p>
                                                </div>

                                                <div className='__center'>
                                                    <h4> ${val?.package}</h4>
                                                    <h5> {(key + 1) == (userInfo?.x3_package_id + 1) ? "" : ""} </h5>
                                                </div>

                                                <div className='__bottomBar'>
                                                    {previewIdCheck != 'false' ?
                                                        <div className='__bottomBar'>
                                                            <button type='button' className='btn'> Preview </button>
                                                        </div>
                                                        : (key + 1) == (userInfo?.x3_package_id + 1) ?
                                                            <div>
                                                                {/* // <button type='button' className='btn' onClick={() => activateNow3x((val?._id))}> Activate </button>

                                                            // <button type='button' className='btn' onClick={() => { (userInfo?.package_id > (userInfo?.x3_package_id + 2)) ? approveAndSendTokensX3(val?.package, (key + 1), val?._id) : toasted.error(' You are not eligible for activate 3x package!'); }} disabled={loading}>
                                                            //     {loading ? (
                                                            //         <ButtonLoader LoaderTxt="" />
                                                            //     ) : (
                                                            //         'Activate'

                                                            //     )}  </button> */}

                                                                {allowancebtn3x == 0 && <button type='button' className='btn' onClick={() => { (userInfo?.package_id > (userInfo?.x3_package_id + 2)) ? approveGasX3(val?.package, (key + 1), val?._id) : toasted.error(' You are not eligible for activate 3x package!'); }} disabled={loading}>
                                                                    {loading ? (
                                                                        <ButtonLoader LoaderTxt="" />

                                                                    ) : (
                                                                        'Allowance Gas'
                                                                    )}
                                                                </button>}

                                                                {allowancebtn3x == 1 &&
                                                                    <button type='button' className='btn' onClick={() => { (userInfo?.package_id > (userInfo?.x3_package_id + 2)) ? approveX3KSN(val?.package, (key + 1), val?._id) : toasted.error(' You are not eligible for activate 3x package!'); }} disabled={loading}>
                                                                        {loading ? (
                                                                            <ButtonLoader LoaderTxt="" />

                                                                        ) : (
                                                                            'Allowance KSN'
                                                                        )}
                                                                    </button>}

                                                                {allowancebtn3x == 2 &&
                                                                    <button type='button' className='btn' onClick={() => { (userInfo?.package_id > (userInfo?.x3_package_id + 2)) ? approveX3uprade(val?.package, (key + 1), val?._id) : toasted.error(' You are not eligible for activate 3x package!'); }} disabled={loading}>
                                                                        {loading ? (
                                                                            <ButtonLoader LoaderTxt="" />

                                                                        ) : (
                                                                            'Activate Now'
                                                                        )}
                                                                    </button>}

                                                            </div>

                                                            : (key) < (userInfo?.x3_package_id) ? <button type='button' className='btn'> Activated </button> : <button type='button' className='btn'> Activate Later</button>}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                                }


                            </div>
                        </div>
                    </section>





                    <section className='Programs-sec overflow-hidden d-none'>
                        <div className='Programs-header text-center'>
                            <Fade top>
                                <h3 className='fw-bold' > {title} x3 PRO</h3>
                            </Fade>
                        </div>

                        <div className='__x2_package'>
                            <div className='row'>

                                {allPackageList4X?.map((val, key) => {
                                    // console.log('Condition4X',Condition4X[key],key);
                                    return (
                                        <div className='col-xl-3 col-md-4 col-sm-6' key={key}>
                                            <div className={(key + 1) == (userInfo?.x4_package_id + 1) ? "__x2Card card activated" : "__x2Card card "}>
                                                {/* <Link to="#" className='stretched-link'></Link> */}
                                                <div className='_right'>
                                                    <p> {(key + 1)}</p>
                                                </div>

                                                <div className='__center'>
                                                    <h4> ${val?.package}</h4>
                                                    <h5> {(key + 1) == (userInfo?.x4_package_id + 1) ? "" : ""} </h5>
                                                </div>

                                                <div className='__bottomBar'>
                                                    {previewIdCheck != 'false' ?
                                                        <div className='__bottomBar'>
                                                            <button type='button' className='btn'> Preview </button>
                                                        </div>
                                                        : ((userInfo?.package) >= (Condition4X[key])) && ((key + 1) == (userInfo?.x4_package_id + 1)) ?
                                                            <div>
                                                                {/* // <button type='button' className='btn' onClick={() => activateNow3x((val?._id))}> Activate </button>

                                                            // <button type='button' className='btn' onClick={() => { (userInfo?.package_id > (userInfo?.x3_package_id + 2)) ? approveAndSendTokensX3(val?.package, (key + 1), val?._id) : toasted.error(' You are not eligible for activate 3x package!'); }} disabled={loading}>
                                                            //     {loading ? (
                                                            //         <ButtonLoader LoaderTxt="" />
                                                            //     ) : (
                                                            //         'Activate'

                                                            //     )}  </button> */}

                                                                {allowancebtn3xPro == 0 && <button type='button' className='btn' onClick={() => { (((key + 1) == (userInfo?.x4_package_id + 1))) ? approveGasX3Pro(val?.package, (key + 1), val?._id) : toasted.error(' You are not eligible for activate 3x Pro package!'); }} disabled={loading}>
                                                                    {loading ? (
                                                                        <ButtonLoader LoaderTxt="" />

                                                                    ) : (
                                                                        'Allowance Gas'
                                                                    )}
                                                                </button>}

                                                                {allowancebtn3xPro == 1 &&
                                                                    <button type='button' className='btn' onClick={() => { (((userInfo?.package) >= (Condition4X[key])) && ((key + 1) == (userInfo?.x4_package_id + 1))) ? approveX3KSNPro(val?.package, (key + 1), val?._id) : toasted.error(' You are not eligible for activate 3x Pro package!'); }} disabled={loading}>
                                                                        {loading ? (
                                                                            <ButtonLoader LoaderTxt="" />

                                                                        ) : (
                                                                            'Allowance KSN'
                                                                        )}
                                                                    </button>}

                                                                {allowancebtn3xPro == 2 &&
                                                                    <button type='button' className='btn' onClick={() => { (((userInfo?.package) >= (Condition4X[key])) && ((key + 1) == (userInfo?.x4_package_id + 1))) ? approveX3upradePro(val?.package, (key + 1), val?._id) : toasted.error(' You are not eligible for activate 3x Pro package!'); }} disabled={loading}>
                                                                        {loading ? (
                                                                            <ButtonLoader LoaderTxt="" />

                                                                        ) : (
                                                                            'Activate Now'
                                                                        )}
                                                                    </button>}

                                                            </div>

                                                            : (key) < (userInfo?.x4_package_id) ? <button type='button' className='btn'> Activated </button> : <button type='button' className='btn'> Activate Later</button>}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                                }


                            </div>
                        </div>
                    </section>


                    <div className='dashboard-recent-sec position-relative'>
                        {/* <div className='blue-effect-top'></div>
        <div className='blue-effect-bottom'></div> */}
                        <div className=''>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='sec-header'>
                                        <h2>Platform recent activity
                                            {/* <span id="second-app-title"><InfoIcon /></span>
                                            <Tooltip
                                                className='Tooltip_s'
                                                anchorId="second-app-title"
                                                place="top"
                                                variant="info"
                                            // content=" Earn BUSD real-time global events with links to transactions in blockchain"
                                            /> */}
                                        </h2>
                                    </div>
                                </div>

                                <div className='col-md-6'>
                                    <div className='card border-0 members-card'>
                                        <div className='member-sec-a'>
                                            <h6 className='card-heading-members' >Members total
                                                {/* <span id='members-tooltip' ><HelpOutlineIcon /></span>
                                                <Tooltip
                                                    className='Tooltip_s'
                                                    anchorId="members-tooltip"
                                                    place="top"
                                                    variant="info"
                                                // content="Total number of members in Earn BUSD and last 24 hours change"
                                                /> */}
                                            </h6>
                                            <h4>{allUsers?.totalUsers} <span className="up-down-activity text-green"> <small> </small><ArrowUpwardIcon /> {allUsers?.usersLast24Hours} </span></h4>
                                        </div>
                                        {/* ======  */}
                                        <div className='member-sec-a border-0 mb-0 pb-0'>
                                            <h6 className='card-heading-members' >Members received, {CurrencySec}
                                                {/* <span id='members-tooltip-received' ><HelpOutlineIcon /></span>
                                                <Tooltip
                                                    className='Tooltip_s'
                                                    anchorId="members-tooltip-received"
                                                    place="top"
                                                    variant="info"
                                                // content="Total amount of BUSD received by all members of Earn BUSD and last 24 hours change"
                                                /> */}
                                            </h6>
                                            <h4>{allUsers?.TotalIncome}  <span className="up-down-activity text-green"> <small> </small><ArrowUpwardIcon /> {allUsers?.LastDayIncome}  </span>      </h4>
                                        </div>
                                    </div>
                                </div>


                                <div className='col-md-6'>
                                    <div className='card border-0 members-card'>
                                        <h4 className='card-heading-members' >{title} Contracts</h4>
                                        <div className='card-body-a'>
                                            {/* <p className='mb-0'>x2 </p> */}
                                            <div className='r-token'>
                                                <p className='mb-0 token-name'>{cyclixContractAddress}</p>
                                                <div className='fake-btn' onClick={() => { copyToClipboard(cyclixContractAddress, 'Contract address copied!') }}>
                                                    <ContentCopyIcon />
                                                </div>
                                                {/* <div className='fake-btn ms-1'>
                                                    <Link to='/'>
                                                        <ShareIcon />
                                                    </Link>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className='d-flex w-100'>
                                            <div className='Transactions-mode'>
                                                <h6 className='card-heading-members' >Transactions made</h6>
                                                <h4>{allUsers?.zeroTotalIncome} <span className="up-down-activity text-green"> <small> </small><ArrowUpwardIcon /> {allUsers?.zeroLastDayIncome}  </span> </h4>


                                            </div>
                                            <div className='Transactions-mode'>
                                                <h6 className='card-heading-members'>turnover,{CurrencySec}</h6>
                                                <h4>{allUsers?.TotalTurnover} <span className="up-down-activity text-green"> <small> </small><ArrowUpwardIcon /> {allUsers?.LastDayTurnover}  </span> </h4>

                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className='col-12'>
                                    <div className='card border-0 activity-card '>
                                        <div className='table-responsiv__main'>
                                            <table className="table table__main">
                                                <tbody>
                                                    {getTransactoin ? '' : <Loader LoaderTxt="Please wait..." />}

                                                    {getTransactoin?.map((val, key) => {
                                                        return (
                                                            <tr key={key}>
                                                                <td>
                                                                    <div className='id_profile'>
                                                                        {val?.description !== 'new user joined' && val?.description !== 'new upgrade user' ?

                                                                            <div className='id_icon id_wallet'>
                                                                                <AccountBalanceWalletIcon />
                                                                            </div>

                                                                            :
                                                                            <div className='id_icon addUser'>
                                                                                <PersonAddAltRounded />
                                                                            </div>
                                                                        }

                                                                        <div className='reffral_txt'>
                                                                            <span className='__txt'>{val?.description == 'new user joined' || val?.description == 'new upgrade user' ? <span className='description_tt'> {val?.description} </span> : ''}</span>
                                                                            <Link className='btn'> {val?.username == '0x0000000000000000000000000000000000000000' ? 'Burning' : 'ID ' + val?.username}</Link>
                                                                        </div>

                                                                        {val?.description !== 'new user joined' && val?.description !== 'new upgrade user' && <p className='blanace-profit' > +{val?.amount?.toFixed(2)} {CurrencySec}  {val?.description !== 'new user joined' ? val?.type !== 'auto_pool_energy' ? <p className='__xvalue'> in <span>x2</span> </p> : <p className='__xvalue'> <span>x3</span> </p> : ''}</p>}
                                                                    </div>
                                                                </td>
                                                                {/* <td>{val?.type?.replace('_', ' ')?.toUpperCase()}</td> */}
                                                                {/* <td >
                                                                    <p className='New__overflow__id'><span>{val?.description}</span></p>
                                                                </td> */}

                                                                {/* <td> {val?.description?.substring(0, 30) + '....' + val?.description?.substring(val?.description?.length - 0, val?.description?.length)}</td> */}
                                                                <td className='time text-center'>
                                                                    <div className='time-a'>
                                                                        <a href={blockChain_url + val?.hash} target='_blank'>    <OpenInNewIcon /> </a>
                                                                        <p>{calcuTime(val.created_at)}</p>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className='btn-table'>
                                            <div onClick={() => setpage(page + 1)} className='btn'> <RemoveRedEyeIcon /> See More activity </div>
                                        </div>
                                    </div>

                                    {/* <DashbordTable /> */}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {/* update wallet address */}

            {previewIdCheck != 'false' ? '':
                <Modal open={open} onClose={handleClose} center
                    classNames={{
                        modal: "modal_sec --update-modal",
                        modalContainer: "modal__container__sec"
                    }}>
                    <div className='__update-sec'>
                        <div className='__update-header'>
                            <h2>{title} shifting Modal</h2>
                            <p>Please make sure to enter correct details, once submitted cannot be changed.</p>
                        </div>
                        <form>

                            <div className='input-group --input-flex'>

                                <input type="text" name="input1" placeholder="Enter Your Wallet Address" value={props?.maskData?.address} className="form-control" />
                                <button type='button' className='btn --connect-wallet' onClick={() => { props?.connectWallet('metamsk') }}> Connect Wallet </button>
                            </div>

                            {/* <div className='input-group'>
                        <input type="text" name="input1" placeholder="Enter Full Name" value={updateName} onChange={(e) => {setupdateName(e.target.value)}}  className="form-control" />
                    </div> */}

                            <div className='input-group'>
                                <input type="number" name="input1" onChange={(e) => { setupdatePhone(e.target.value) }} value={updatePhone} placeholder="Enter Phone number" className="form-control" />
                            </div>

                            <div className='input-group'>
                                <input type="text" name="input1" onChange={(e) => { setupdateEmail(e.target.value) }} value={updateEmail} placeholder="Enter Email Address" className="form-control" />
                            </div>

                            <div className='input-group'>
                                <input type="text" name="input1" placeholder="Enter Solana Wallet Address" onChange={(e) => { setupdateWallet(e.target.value) }} value={updateWallet} className="form-control" />
                            </div>
                            <div className='__details'>
                                <p> <span> Note: </span> download phantom wallet and copy solana wallet address paste here. </p>
                            </div>

                            <button className="btn update-btn" type='button' onClick={(e) => { profileApproveGasX3Pro(e) }}> Update Profile </button>
                        </form>


                    </div>

                </Modal>}


        </>
    );
}

export default Dashboard;
